import { action, thunk } from 'easy-peasy'
import axios from 'axios'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { getToken } from './App'

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
    let target = this
    return target.split(search).join(replacement)
}

let jwtToken = getToken()
const currentTenantId = localStorage.getItem('currentTenantId')

const Store = {


    sanitizePayload: (obj) => {
        let obj2 = {}
        for (const key in obj) {
            obj2[key] = obj[key] === undefined ? null : obj[key]
        }
        return obj2
    },

    context: action((state, payload) => {
        state.context = payload
    }),

    getContext: thunk(async (actions, payload) => {
        jwtToken = getToken()
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/context`
            const { data } = await axios.get(url, { headers: { authorization: getToken() ? jwtToken : payload, tenant: currentTenantId } })
            actions.context(data)
            if (data && data.tenant) {
                actions.setCurrentTenantId(data.tenant._id)
                actions.setCurrentTenantName(data.tenant.name)
            }
            return data
        } catch (error) {
            console.log("Store.getContext error", error)
            actions.context(error)
            return Promise.reject(error)
        }
    }),


    //////////////////////////////////////////////
    // CARPARKS
    //////////////////////////////////////////////

    carparks: action((state, payload) => {
        state.carparks = payload
    }),

    carpark: action((state, payload) => {
        state.carpark = payload
    }),

    getCarparks: thunk(async (actions) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/carpark`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.carparks(data)
            return data
        } catch (error) {
            console.log("Store.getCarparks error", error)
            actions.carparks(error.response.data)
            return { error: error }
        }
    }),

    getCarpark: thunk(async (actions, payload) => {
        try {
            const carparkId = payload
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/carpark/${carparkId}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.carpark(data)
            return data
        } catch (error) {
            console.log("Store.getCarpark error", error)
            actions.carpark(error.response.data)
            return { error: error }
        }
    }),


    //////////////////////////////////////////////
    // CARS
    //////////////////////////////////////////////

    cars: action((state, payload) => {
        state.cars = payload
    }),

    car: action((state, payload) => {
        state.car = payload
    }),

    createCar: thunk(async (actions, payload) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car`
            const { data } = await axios.post(url, payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.car(data)
            return data
        } catch (error) {
            console.log("Store.createCar error", error)
            actions.car(error.response.data)
            return { error: error }
        }
    }),

    carCsvUpload: thunk(async (actions, payload) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/csv`
            let formData = new FormData()
            formData.append('file', payload, { type: 'text/csv' })
            const { data } = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    authorization: getToken(),
                    tenant: currentTenantId
                }
            })
            return data
        } catch (error) {
            console.log("Store.carCsvUpload error", error)
            return { error: error }
        }
    }),

    updateCar: thunk(async (actions, payload) => {
        console.log("Store.updateCar", payload)
        if (!(payload && payload._id)) {
            throw new Error("Invalid payload!")
        }
        try {
            const values = Store.sanitizePayload({
                name: payload.name,
                numberPlate: payload.numberPlate,
                qr: payload.qr,
                type: payload.type,
                customerName: payload.customerName,
                vin: payload.vin
            })
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/${payload._id}`
            const { data } = await axios.patch(url, values, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.car(data)
            return data
        } catch (error) {
            console.log("Store.updateCar error", error)
            actions.car(error.response.data)
            return { error: error }
        }
    }),

    deleteCar: thunk(async (actions, payload) => {
        console.log("Store.deleteCar", payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/${payload}`
            const { data } = await axios.delete(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.car(data)
            return data
        } catch (error) {
            console.log("Store.deleteCar error", error)
            actions.car(error.response.data)
            return { error: error }
        }
    }),

    getCars: thunk(async (actions, payload) => {
        // console.log("getCars", payload)
        try {
            let url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/list?`

            if (payload && payload.fetchDeletedCars === true) {
                url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/listDeleted?`
            }

            let body = {}
            if (payload && payload.search && payload.search.length > 0) {
                body['$or'] = [
                    { name: { "$regex": payload.search, "$options": "i" } },
                    { type: { "$regex": payload.search, "$options": "i" } },
                    { numberPlate: { "$regex": payload.search, "$options": "i" } },
                    { numberPlateSanitized: { "$regex": payload.search.replaceAll(" ", "").replaceAll("-", "").toUpperCase().trim(), "$options": "i" } },
                    { qr: { "$regex": payload.search, "$options": "i" } },
                    { vin: { "$regex": payload.search, "$options": "i" } },
                ]
            }

            // body['$and'] = [
            //     { state: { "$eq": (payload && payload.fetchExternalCarsOnly) ? "extern" : "intern" } },
            // ]
            if (payload && payload.fetchExternalCarsOnly) {
                body['$and'] = [{ state: { "$eq": "extern" } }]
            }

            url += `filter=${encodeURIComponent(JSON.stringify(body))}`
            if (payload && payload.page) {
                url += `&page=${payload.page}`
            } else {
                url += `&page=0`
            }
            if (payload && payload.limit) {
                url += `&limit=${payload.limit}`
            } else {
                url += `&limit=${100}`
            }
            url += "&sort=name"

            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.cars(data)
            return data
        } catch (error) {
            console.log("Store.getCars error", error)
            actions.cars(error.response.data)
            return { error: error }
        }
    }),

    getCarsWithStrictEquality: thunk(async (actions, payload) => {
        try {
            if (!payload.numberPlate && !payload.vin) {
                return []
            }

            let url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/list?`

            let body = { '$or': [] }
            if (payload && payload.numberPlate && payload.numberPlate.length > 0) {
                body['$or'].push({ numberPlateSanitized: { "$eq": payload.numberPlate.replaceAll(" ", "").replaceAll("-", "").toUpperCase().trim() } })
            }
            if (payload && payload.vin && payload.vin.length > 0) {
                body['$or'].push({ vin: { "$eq": payload.vin.toUpperCase().trim() } })
            }
            if (payload && payload.findOnlyCarsWithAnIdNotEqualTo && payload.findOnlyCarsWithAnIdNotEqualTo.length > 0) {
                body['$and'] = [
                    { _id: { "$ne": payload.findOnlyCarsWithAnIdNotEqualTo } },
                ]
            }

            url += `filter=${encodeURIComponent(JSON.stringify(body))}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.cars(data)
            return data
        } catch (error) {
            console.log("Store.getCars error", error)
            actions.cars(error.response.data)
            return { error: error }
        }
    }),

    getCar: thunk(async (actions, payload) => {
        try {
            const carId = payload.carId
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/car/${carId}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.car(data)
            return data
        } catch (error) {
            console.log("Store.getCar error", error)
            actions.car(error.response.data)
            return { error: error }
        }
    }),

    resetCar: thunk(async (actions, payload) => {
        actions.car(undefined)
    }),

    //////////////////////////////////////////////
    // DECKS
    //////////////////////////////////////////////

    decks: action((state, payload) => {
        state.decks = payload
    }),

    deck: action((state, payload) => {
        state.deck = payload
    }),

    getDecks: thunk(async (actions) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/deck`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.decks(data)
            return data
        } catch (error) {
            console.log("Store.getDecks error", error)
            actions.decks(error.response.data)
            return { error: error }
        }
    }),

    getDeck: thunk(async (actions, payload) => {
        try {
            const deckId = payload
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/deck/${deckId}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.deck(data)
            return data
        } catch (error) {
            console.log("Store.getDeck error", error)
            actions.deck(error.response.data)
            return { error: error }
        }
    }),


    //////////////////////////////////////////////
    // EMPLOYEES
    //////////////////////////////////////////////

    employees: action((state, payload) => {
        state.employees = payload
    }),

    employee: action((state, payload) => {
        state.employee = payload
    }),

    createEmployee: thunk(async (actions, payload) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/user`
            const { data } = await axios.post(url, payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.employee(data)
            return data
        } catch (error) {
            console.log("Store.createEmployee error", error)
            actions.employee(error.response.data)
            return { error: error }
        }
    }),

    updateEmployee: thunk(async (actions, payload) => {
        console.log("Store.updateEmployee", payload)
        if (!(payload && payload._id)) {
            throw new Error("Invalid payload!")
        }
        try {
            const values = Store.sanitizePayload({
                lastName: payload.lastName,
                firstName: payload.firstName,
                email: payload.email,
                role: payload.role,
            })
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/user/${payload._id}`
            const { data } = await axios.patch(url, values, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.employee(data)
            return data
        } catch (error) {
            console.log("Store.updateEmployee error", error)
            actions.employee(error.response.data)
            return { error: error }
        }
    }),

    deleteEmployee: thunk(async (actions, payload) => {
        console.log("Store.deleteEmployee", payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/user/${payload}`
            const { data } = await axios.delete(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.employee(data)
            return data
        } catch (error) {
            console.log("Store.deleteEmployee error", error)
            actions.employee(error.response.data)
            return { error: error }
        }
    }),

    getEmployees: thunk(async (actions, payload) => {
        try {
            let url = `${window.__RUNTIME_CONFIG__.BASE_URL}/user/list?`
            let body = {}
            if (payload && payload.search) {
                body['$or'] = [
                    { lastName: { "$regex": payload.search, "$options": "i" } },
                    { firstName: { "$regex": payload.search, "$options": "i" } },
                    { email: { "$regex": payload.search, "$options": "i" } },
                    { role: { "$regex": payload.search, "$options": "i" } },
                ]
            }

            url += `filter=${encodeURIComponent(JSON.stringify(body))}`
            if (payload && payload.page) {
                url += `&page=${payload.page}`
            } else {
                url += `&page=0`
            }
            if (payload && payload.limit) {
                url += `&limit=${payload.limit}`
            } else {
                url += `&limit=${100}`
            }
            url += "&sort=lastName"

            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.employees(data)
            return data
        } catch (error) {
            console.log("Store.getEmployees error", error)
            actions.employees(error.response.data)
            return { error: error }
        }
    }),

    getEmployee: thunk(async (actions, payload) => {
        try {
            const employeeId = payload.employeeId
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/user/${employeeId}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.employee(data)
            return data
        } catch (error) {
            console.log("Store.getEmployee error", error)
            actions.employee(error.response.data)
            return { error: error }
        }
    }),

    resetEmployee: thunk(async (actions, payload) => {
        actions.employee(undefined)
    }),

    //////////////////////////////////////////////
    // HISTORY
    //////////////////////////////////////////////
    historyData: action((state, payload) => {
        state.historyData = payload
    }),

    getHistory: thunk(async (actions, payload) => {
        try {
            let url = `${window.__RUNTIME_CONFIG__.BASE_URL}/history/list?`
            let body = {}
            if (payload && payload.carId) {
                body['$and'] = [
                    { car: { "$eq": payload.carId } },
                ]
            }

            url += `filter=${encodeURIComponent(JSON.stringify(body))}`
            if (payload && payload.page) {
                url += `&page=${payload.page}`
            } else {
                url += `&page=0`
            }
            if (payload && payload.limit) {
                url += `&limit=${payload.limit}`
            } else {
                url += `&limit=${100}`
            }
            url += "&sort=-createdAt"

            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.historyData(data)
            return data
        } catch (error) {
            console.log("Store.getHistory error", error)
            actions.historyData(error.response.data)
            return { error: error }
        }
    }),

    //////////////////////////////////////////////
    // KEYLOCKERS
    //////////////////////////////////////////////

    keylocker: action((state, payload) => {
        state.keylocker = payload
    }),

    keylockers: action((state, payload) => {
        state.keylockers = payload
    }),

    getKeylockers: thunk(async (actions) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/keylocker`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.keylockers(data)
            return data
        } catch (error) {
            console.log("Store.getKeylockers error", error)
            actions.keylockers(error.response.data)
            return { error: error }
        }
    }),
    createKeylocker: thunk(async (actions, payload) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/keylocker`
            const { data } = await axios.post(url, payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.keylocker(data)
            return data
        } catch (error) {
            console.log("Store.createKeylocker error", error)
            actions.keylocker(error.response.data)
            return { error: error }
        }
    }),

    deleteKeylocker: thunk(async (actions, payload) => {
        console.log("Store.deleteKeylocker", payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/keylocker/${payload}`
            const { data } = await axios.delete(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.keylocker(data)
            return data
        } catch (error) {
            console.log("Store.deleteKeylocker error", error)
            actions.keylocker(error.response.data)
            return { error: error }
        }
    }),
    //////////////////////////////////////////////
    // SPOTS
    //////////////////////////////////////////////

    spots: action((state, payload) => {
        state.spots = payload
    }),

    spot: action((state, payload) => {
        state.spot = payload
    }),

    createSpot: thunk(async (actions, payload) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/spot`
            const { data } = await axios.post(url, payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.spot(data)
            return data
        } catch (error) {
            console.log("Store.createSpot error", error)
            actions.spot(undefined)
            return { error: error }
        }
    }),

    updateSpot: thunk(async (actions, payload) => {
        console.log("Store.updateSpot", payload)
        if (!(payload && payload._id)) {
            throw new Error("Invalid payload!")
        }
        try {
            const values = Store.sanitizePayload({
                lastName: payload.lastName,
                firstName: payload.firstName,
                email: payload.email,
                role: payload.role,
            })
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/spot/${payload._id}`
            const { data } = await axios.patch(url, values, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.spot(data)
            return data
        } catch (error) {
            console.log("Store.updateSpot error", error)
            actions.spot(error.response.data)
            return { error: error }
        }
    }),

    deleteSpot: thunk(async (actions, payload) => {
        console.log("Store.deleteSpot", payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/spot/${payload}`
            const { data } = await axios.delete(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.spot(data)
            return data
        } catch (error) {
            console.log("Store.deleteSpot error", error)
            actions.spot(error.response.data)
            return { error: error }
        }
    }),

    getSpots: thunk(async (actions) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/spot`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.spots(data)
            return data
        } catch (error) {
            console.log("Store.getSpots error", error)
            actions.spots(error.response.data)
            return { error: error }
        }
    }),

    getSpot: thunk(async (actions, payload) => {
        try {
            const spotId = payload.spotId
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/spot/${spotId}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.spot(data)
            return data
        } catch (error) {
            console.log("Store.getSpot error", error)
            actions.spot(error.response.data)
            return { error: error }
        }
    }),

    resetSpot: thunk(async (actions, payload) => {
        actions.spot(undefined)
    }),


    //////////////////////////////////////////////
    // TASKS
    //////////////////////////////////////////////

    tasks: action((state, payload) => {
        state.tasks = payload
    }),

    task: action((state, payload) => {
        state.task = payload
    }),

    createTask: thunk(async (actions, payload) => {
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/task`
            const { data } = await axios.post(url, payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.task(data)
            return data
        } catch (error) {
            console.log("Store.createTask error", error)
            actions.task(error.response.data)
            return { error: error }
        }
    }),

    updateTask: thunk(async (actions, payload) => {
        console.log("Store.updateTask", payload)
        if (!(payload && payload._id)) {
            throw new Error("Invalid payload!")
        }
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/task/${payload._id}`
            const { data } = await axios.patch(url, payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.task(data)
            return data
        } catch (error) {
            console.log("Store.updateTask error", error)
            actions.task(error.response.data)
            return { error: error }
        }
    }),

    deleteTask: thunk(async (actions, payload) => {
        console.log("Store.deleteTask", payload, { headers: { authorization: getToken(), tenant: currentTenantId } })
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/task/${payload}`
            const { data } = await axios.delete(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.task(data)
            return data
        } catch (error) {
            console.log("Store.deleteTask error", error)
            actions.task(error.response.data)
            return { error: error }
        }
    }),

    getTasks: thunk(async (actions, payload) => {
        console.log("getTasks", payload)
        try {
            let url = `${window.__RUNTIME_CONFIG__.BASE_URL}/task/list?`
            let body = {}

            // WE ARE CURRENTLY ONLY SEARCHING BY KEYWORD ON THE CLIENT-SIDE, @see Tasks.js -> fetchData()
            // if (payload && payload.search) {
            //     body['$or'] = [
            //         { name: { "$regex": payload.search, "$options": "i" } },
            //         // { customerName: { "$regex": payload.search, "$options": "i" } },
            //         // {
            //         //     user: {
            //         //         firstName: { "$regex": payload.search, "$options": "i" },
            //         //         lastName: { "$regex": payload.search, "$options": "i" }
            //         //     }
            //         // },
            //     ]
            // }
            if (payload.fetchClosedTasks) {
                body['$and'] = [{
                    state: { $in: ["closed", "ended", "locked"] }
                }]
            } else {
                body['$and'] = [{
                    state: { $nin: ["closed", "ended", "locked"] }
                }]
            }

            url += `filter=${encodeURIComponent(JSON.stringify(body))}`
            if (payload && payload.page) {
                url += `&page=${payload.page}`
            } else {
                url += `&page=0`
            }
            if (payload && payload.limit) {
                url += `&limit=${payload.limit}`
            } else {
                url += `&limit=${100}`
            }

            if (payload.fetchClosedTasks) {
                url += "&sort=-dueDate"
            } else {
                url += "&sort=dueDate"
            }

            const { data } = await axios.get(url, {
                headers: { authorization: getToken(), tenant: currentTenantId }
            })
            actions.tasks(data.data)
            return data.data
        } catch (error) {
            console.log("Store.getTasks error", error)
            actions.tasks(error.response.data)
            return { error: error }
        }
    }),

    getTask: thunk(async (actions, payload) => {
        try {
            const taskId = payload.taskId
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/task/${taskId}`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.task(data)
            return data
        } catch (error) {
            console.log("Store.getTask error", error)
            actions.task(error.response.data)
            return { error: error }
        }
    }),

    getTaskSseAllEventSource: thunk((actions, payload) => {
        return new EventSourcePolyfill(`${window.__RUNTIME_CONFIG__.BASE_URL}/task/sse/all`, {
            headers: { authorization: getToken(), tenant: currentTenantId },
            // heartbeatTimeout: Number.MAX_SAFE_INTEGER
            // heartbeatTimeout: 2
        })
    }),

    resetTask: thunk(async (actions, payload) => {
        actions.task(undefined)
    }),


    //////////////////////////////////////////////
    // TENANTS
    //////////////////////////////////////////////

    tenants: action((state, payload) => {
        state.tenants = payload
    }),

    tenant: action((state, payload) => {
        state.tenant = payload
    }),

    getTenants: thunk(async (actions) => {
        console.log('Store.getTenants')
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/tenant`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.tenants(data)
            return data
        } catch (error) {
            console.log("Store.getTenants error", error)
            actions.tenants(error.response.data)
            return { error: error }
        }
    }),

    getCurrentTenantId: thunk(() => {
        return localStorage.getItem('currentTenantId')
    }),

    setCurrentTenantId: thunk(async (actions, payload) => {
        // console.log('setCurrentTenantId', payload)
        localStorage.setItem('currentTenantId', payload)
    }),

    getCurrentTenantName: thunk(async () => {
        return localStorage.getItem('currentTenantName')
    }),

    setCurrentTenantName: thunk(async (actions, payload) => {
        // console.log('setCurrentTenantName', payload)
        localStorage.setItem('currentTenantName', payload)
    }),

    getTenant: thunk(async (actions) => {
        console.log('Store.getTenant', currentTenantId)
        try {
            const url = `${window.__RUNTIME_CONFIG__.BASE_URL}/tenant/current`
            const { data } = await axios.get(url, { headers: { authorization: getToken(), tenant: currentTenantId } })
            actions.tenant(data)
            return data
        } catch (error) {
            console.log("Store.getTenant error", error)
            actions.tenant(error.response.data)
            return { error: error }
        }
    })
}

export default Store