import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import { TextField } from 'mui-rff'
import { Select } from 'mui-rff'
import { MenuItem } from '@mui/material'
import Joi from 'joi'
import { useStoreActions } from 'easy-peasy'
import { Alert } from '@mui/material'

const EmployeeForm = (props) => {
    const { t } = useTranslation()

    console.log("EmployeeForm", props)

    const roles = [
        'ServiceManager',
        'ServiceOperator',
        // 'Viewer'
    ]

    const initialValues = {}

    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const createEmployee = useStoreActions(actions => actions.createEmployee)
    const resetEmployee = useStoreActions(actions => actions.resetEmployee)
    const updateEmployee = useStoreActions(actions => actions.updateEmployee)

    const onSubmit = async (values) => {
        console.log("EmployeeForm onSubmit", values)
        setErrorMessage(undefined)
        setIsSubmitting(true)
        await resetEmployee()

        let result = undefined

        if (props.employee) {
            const valuesCopy = JSON.parse(JSON.stringify(values))
            valuesCopy._id = props.employee._id
            result = await updateEmployee(valuesCopy)
        } else {
            result = await createEmployee(values)
        }

        setIsSubmitting(false)
        if (result.error) {
            setErrorMessage(result.message.includes("E11") ? t('An employee with that email already exists!') : result.message)
            return false
        }

        props.closeDialog()
    }

    const cancel = async () => {
        setIsSubmitting(false)
        props.closeDialog()
    }

    const validate = async (values) => {
        const validationResult = validationSchema.validate(values, { abortEarly: false })
        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            setIsSubmitting(false)
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
            })
            return errorMessages
        }
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    const validationSchema = Joi.object({
        firstName: Joi.string().min(1).max(64).required(),
        lastName: Joi.string().min(1).max(64).required(),
        email: Joi.string().email({ tlds: false }),
        role: Joi.string().valid('ServiceManager', 'ServiceOperator', 'Viewer').required()
    })


    if (props.employee) {
        initialValues.firstName = props.employee.firstName
        initialValues.lastName = props.employee.lastName
        initialValues.role = props.employee.role
        initialValues.email = props.employee.email
    }

    return (
        <>
            <Dialog open={true}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validate}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>{props.employee ? t('Edit Employee') : t('Add Employee')}</DialogTitle>
                            <DialogContent>
                                <TextField sx={{ mb: '1.5rem' }} label={t('First name')} name="firstName" required={true} />
                                <TextField sx={{ mb: '1.5rem' }} label={t('Last name')} name="lastName" required={true} />
                                <TextField sx={{ mb: '0.5rem' }} label={t('Email')} name="email" />
                                <Select name="role" label={t('Select the user role')} formControlProps={{ margin: 'normal' }} required={true}>
                                    {roles.map((role, index) => {
                                        return (
                                            <MenuItem key={`role-${index}`} value={role}>{t(`Role.${role}`)}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {errorMessage &&
                                    <Alert severity="warning">{errorMessage}</Alert>
                                }
                            </DialogContent>
                            <DialogActions sx={{ mb: '1rem' }} >
                                <Button onClick={() => { cancel() }} variant="outlined">{t('Cancel')}</Button>
                                <Button type="submit" disabled={isSubmitting} variant="contained">{t('form.Submit')}</Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </>
    )
}

export default EmployeeForm