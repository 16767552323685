// These are all referencing mui community icons
// @svgr-iconkit/material-community
// https://www.npmjs.com/package/@svgr-iconkit/material-community
// https://svgr-iconkit.dev/explorer/material-community/

export const stateIcon = (itemState) => {
    switch (itemState) {
        case 'open':
            return { name: 'new-box', color: '#d5001c' }
        case 'claimed':
            return { name: 'walk', color: '#313639' }
        case 'closed':
            return { name: 'check-bold', color: '#313639' }
        case 'ended':
            return { name: 'check-bold', color: '#313639' }
        case 'locked':
            return { name: 'key', color: '#313639' }
        case 'in work':
            return { name: 'check', color: '#313639' }
        case 'driving':
            return { name: 'car-hatchback', color: '#313639' }
        case 'parked':
            return { name: 'parking', color: '#313639' }
        default:
            console.log('Icons.js stateIcon not found', itemState)
            return { name: 'progress-question', color: '#ff36ff' }
    }
}

export const spotTypeIcon = (itemType) => {
    switch (itemType) {
        case 'keylocker':
            return { name: 'shield-lock', color: '#fda232' }
        case 'servicedesk':
            return { name: 'alpha-s-circle', color: '#1b930e' }
        case 'parkingspot':
            return { name: 'parking', color: '#313639' }
        case 'parkingspot-occupied':
            return { name: 'car', color: '#2151ff' }
        case 'parkingspot-reserved':
            return { name: 'pause-circle', color: '#dd6357' }
        case 'repair':
            return { name: 'wrench', color: '#313639' }
        case 'pickup':
            return { name: 'arrow-up-bold-hexagon-outline', color: '#313639' }
        default:
            console.log('Icons.js spotTypeIcon not found', itemType)
            return { name: 'progress-question', color: '#ff36ff' }
    }
}


export const typeIcon = (itemType) => {

    switch (itemType) {
        case 'park':
            return { name: 'parking', color: '#313639' }
        case 'move':
            return { name: 'car', color: '#313639' }
        case 'repair':
            return { name: 'wrench', color: '#313639' }
        case 'clean':
            return { name: 'vacuum', color: '#313639' }
        case 'return':
            return { name: 'undo', color: '#313639' }
        case 'extern':
            return { name: 'road-variant', color: '#313639' }
        default:
            console.log('Icons.js typeIcon not found', itemType)
            return { name: 'progress-question', color: '#ff36ff' }
    }
}