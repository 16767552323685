import React from "react"
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch"
import Icon from '@svgr-iconkit/material-community'
import { spotTypeIcon } from '../../utils/icons/Icons'
import { CircularProgress } from "@mui/material"
import { Container } from "@mui/material"
import { useState } from "react"
import { useRef } from "react"
import QrPreview from "../qrpreview/QrPreview"

/* @see: https://github.com/prc5/react-zoom-pan-pinch*/
/* @see: https://prc5.github.io/react-zoom-pan-pinch */

const DeckMapView = (props) => {

    const markerSize = 50

    const [domImgObj, setDomImgObj] = useState(undefined)
    const [isImageLoading, setIsImageLoading] = useState(true)
    const [selectedSpot, setSelectedSpot] = useState(props.selectedSpot)
    const [containerWidth, setContainerWidth] = useState(1)
    const [containerHeight, setContainerHeight] = useState(1)

    const transformWrapperRef = useRef()

    const onSpotSelected = (spot) => {
        setSelectedSpot(spot)
        if (props.spotSelectedCallback) {
            props.spotSelectedCallback(spot)
        }
    }
    const onMapClick = (e) => {
        if (props.mapClickCallback) {
            props.mapClickCallback({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        }
    }
    const onMapDoubleClick = (e) => {
        if (props.mapDoubleClickCallback) {
            props.mapDoubleClickCallback({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        }
    }

    const until = (conditionFunction) => {
        const poll = (resolve) => {
            if (conditionFunction()) {
                resolve()
            } else {
                setTimeout(() => poll(resolve), 400)
            }
        }
        return new Promise(poll);
    }

    const onImageLoad = async (e) => {
        setDomImgObj(e.target)
        setIsImageLoading(false)
        const currentDeckId = props.deck ? props.deck._id : undefined
        const spotDeckId = selectedSpot && selectedSpot.deck ? selectedSpot.deck : undefined

        await until(() => {
            console.log("onImageLoad until", containerWidth, containerWidth > 1, props.wrapperRef.current ? props.wrapperRef.current.offsetWidth : NaN)
            if (props.wrapperRef.current && props.wrapperRef.current.offsetWidth > 0) {
                setContainerWidth(props.wrapperRef.current.offsetWidth)
                setContainerHeight(props.wrapperRef.current.offsetHeight)
                return true
            }
            return false
        })

        if (spotDeckId && (spotDeckId === currentDeckId)) { // only zoom to spot if we're currently showing the correct deck
            zoomToSpot(selectedSpot, 0.75, props.wrapperRef.current.offsetWidth, props.wrapperRef.current.offsetHeight)
        } else if (e.target) {
            fitDomObjectToContainer(e.target, props.wrapperRef.current.offsetWidth, props.wrapperRef.current.offsetHeight)
        }
    }

    const fitDomObjectToContainer = async (domImg, overrideContainerWidth = undefined, overrideContainerHeight = undefined) => {
        const { setTransform } = transformWrapperRef.current
        const cw = overrideContainerWidth ? overrideContainerWidth : containerWidth
        const ch = overrideContainerHeight ? overrideContainerHeight : containerHeight
        const scale = ch / domImg.naturalHeight
        console.log("fitDomObjectToContainer", domImg.naturalWidth, domImg.naturalHeight, cw, ch, scale)
        setTransform(
            -((domImg.naturalWidth / 2 * scale) - cw / 2),
            -((domImg.naturalHeight / 2 * scale) - ch / 2),
            scale,
            250,
            'easeOutQuad')
    }

    const zoomToSpot = async (spot, scale = 0.75, overrideContainerWidth = undefined, overrideContainerHeight = undefined) => {
        const { setTransform } = transformWrapperRef.current
        const cw = overrideContainerWidth ? overrideContainerWidth : containerWidth
        const ch = overrideContainerHeight ? overrideContainerHeight : containerHeight
        console.log("zoomToSpot", scale, containerWidth, containerHeight, overrideContainerWidth, overrideContainerHeight)
        setTransform(
            -((spot.x * scale) - cw / 2),
            -((spot.y * scale) - ch / 2),
            scale,
            250,
            'easeOutQuad')
    }

    const onImageLoadError = (e1, e2) => {
        console.log('onImageLoadError', e1, e2)
    }

    const getSpotTypeIcon = (spot) => {
        let icon = undefined
        if (spot.car) {
            icon = spotTypeIcon('parkingspot-occupied')
        } else if (spot.reserved) {
            icon = spotTypeIcon('parkingspot-reserved')
        } else {
            icon = spotTypeIcon(spot.type)
        }
        return icon
    }

    return (
        <TransformWrapper
            initialScale={0.25}
            disabled={false}
            minScale={0.125}
            maxScale={1.00}
            limitToBounds={false}
            centerZoomedOut={false}
            centerOnInit={false}
            doubleClick={{ disabled: true }}
            wheel={{ step: 0.25 }}
            style={{ position: 'absolute' }}
            ref={transformWrapperRef}
        >
            {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
                <>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1000
                    }}>
                        <button onClick={() => zoomIn()} style={{ marginLeft: 2, marginRight: 2, width: 24, height: 24 }}>+</button>
                        <button onClick={() => zoomOut()} style={{ width: 24, height: 24 }}>-</button>
                        <button onClick={() => fitDomObjectToContainer(domImgObj)} style={{ marginLeft: 2, width: 24, height: 24 }}>x</button>
                    </div>

                    {props.deck && selectedSpot &&
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            zIndex: 1000,
                            textAlign: 'right',
                            backgroundColor: 'rgba(0.49, 0.54, 0.57, 0.35',
                            borderBottom: '1px solid #313639',
                            borderLeft: '1px solid #313639',
                            color: '#fff',
                            padding: '0.25rem 0.5rem',
                            cursor: 'default',
                            fontSize: '0.75rem',
                            fontWeight: 'bold',
                            userSelect: 'none'
                        }}>
                            {props.deck.carpark && props.deck.carpark.name}<br />
                            {props.deck.name}
                            <div style={{ display: 'block', padding: '1rem 0 0 0' }}><Icon name={spotTypeIcon(selectedSpot.type).name} style={{ width: '1.25rem' }}></Icon></div>
                            {selectedSpot.name}<br />
                            <QrPreview item={selectedSpot}>
                                {selectedSpot.qr}
                            </QrPreview>
                            {props.car &&
                                <>
                                    <div style={{ display: 'block', padding: '1rem 0 0 0' }}><Icon name={'car-back'} style={{ width: '1.25rem' }}></Icon></div>
                                    {props.car.name && <>{props.car.name}<br /></>}
                                    {props.car.type && <>{props.car.type}<br /></>}
                                    {props.car.numberPlate && <>{props.car.numberPlate}<br /></>}
                                    {props.car.vin && <>{props.car.vin}<br /></>}
                                    {props.car.qr && <><QrPreview item={props.car}>{props.car.qr}</QrPreview><br /></>}
                                </>
                            }
                            {selectedSpot && selectedSpot.car &&
                                <>
                                    <div style={{ display: 'block', padding: '1rem 0 0 0' }}><Icon name={'car-back'} style={{ width: '1.25rem' }}></Icon></div>
                                    {selectedSpot.car.name && <>{selectedSpot.car.name}<br /></>}
                                    {selectedSpot.car.type && <>{selectedSpot.car.type}<br /></>}
                                    {selectedSpot.car.numberPlate && <>{selectedSpot.car.numberPlate}<br /></>}
                                    {selectedSpot.car.vin && <>{selectedSpot.car.vin}<br /></>}
                                    {selectedSpot.car.qr && <><QrPreview item={selectedSpot.car}>{selectedSpot.car.qr}</QrPreview><br /></>}
                                </>
                            }
                        </div>
                    }

                    <TransformComponent
                        wrapperStyle={{
                            width: containerWidth,
                            height: containerHeight,
                        }}
                    >
                        <div onClick={onMapClick} onDoubleClick={onMapDoubleClick} >
                            {props.deck && props.deck.image &&
                                <img alt='Parkdeck' src={`${window.__RUNTIME_CONFIG__.BASE_URL}${props.deck.image}`} onLoad={onImageLoad} onError={onImageLoadError}></img>
                            }
                        </div>

                        {isImageLoading &&
                            <Container align="center">
                                <CircularProgress />
                            </Container>
                        }

                        {!isImageLoading && props.deck && props.deck.spots && props.deck.spots.map((spot) => {
                            const icon = getSpotTypeIcon(spot)
                            const iconColor = selectedSpot && selectedSpot._id === spot._id ? '#ff00ff' : icon.color
                            return (
                                <div id={`spot-wrapper-${spot._id}`} title={spot.name} key={`spot-wrapper-${spot._id}`} className={"spotWrapper"}
                                    style={{
                                        position: 'absolute',
                                        marginLeft: spot.x - markerSize / 2,
                                        marginTop: spot.y - markerSize / 2,
                                    }}>
                                    <Icon name={icon.name} style={{ cursor: props.disableSpotClick ? 'default' : 'pointer', color: iconColor, width: markerSize }} onClick={(e) => {
                                        if (props.disableSpotClick) {
                                            return false
                                        }
                                        zoomToSpot(spot)
                                        onSpotSelected(spot)
                                    }} />
                                </div>
                            )
                        })}

                        {!isImageLoading && props.deck && props.deck.keylockers && props.deck.keylockers.map((keylocker) => {
                            const icon = spotTypeIcon('keylocker')
                            return (
                                <div id={`keylocker-wrapper-${keylocker._id}`} title={keylocker.name} key={`keylocker-wrapper-${keylocker._id}`} className={"keylockerWrapper"}
                                    style={{
                                        position: 'absolute',
                                        marginLeft: keylocker.x - markerSize / 2,
                                        marginTop: keylocker.y - markerSize / 2,
                                    }}>
                                    <Icon name={icon.name} style={{ cursor: 'default', color: icon.color, width: markerSize }} />
                                </div>
                            )
                        })}
                    </TransformComponent>
                </>
            )}
        </TransformWrapper >
    )
}
export default DeckMapView
