import { Alert } from '@mui/material'
import { IconButton } from '@mui/material'
import { Typography } from '@mui/material'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import DeckMapView from './DeckMapView'
import EditIcon from '@mui/icons-material/Edit'
import { Link } from '@mui/material'
import { Container } from '@mui/material'
import { CircularProgress } from '@mui/material'

const Deck = () => {
    const { t } = useTranslation()

    const componentRef = useRef()

    const [isUpdating, setIsUpdating] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const context = useStoreState(state => state.context)
    const carpark = useStoreState(state => state.carpark)
    const getCarpark = useStoreActions(actions => actions.getCarpark)
    const deck = useStoreState(state => state.deck)
    const getDeck = useStoreActions(actions => actions.getDeck)

    const { deckId } = useParams()

    const fetchData = useCallback(async () => {
        setIsUpdating(true)
        const deck = await getDeck(deckId)
        if (deck && deck.carpark) {
            await getCarpark(deck.carpark._id)
        }
        setIsUpdating(false)
    }, [getCarpark, getDeck, deckId])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const mapClickCallback = (pos) => {
        if (!pos) {
            return
        }
    }
    const spotSelectedCallback = (spot) => {
        if (!spot) {
            return false
        } else if (spot.isOccupied) {
            setErrorMessage(t("Der Platz ist belegt."))
            return false
        }
        setErrorMessage('')
    }

    const breadcrumbLevels = [{ title: t("Car parks"), url: '/carparks' }]
    if (carpark && deck) {
        breadcrumbLevels.push({ title: carpark.name, url: '/carpark/' + carpark._id })
        breadcrumbLevels.push({ title: deck.name, url: '/deck/' + deck._id })
    }

    return (
        <>
            <Breadcrumbler levels={breadcrumbLevels} />

            <Typography variant="h1" component="div" gutterBottom>
                {t("Deck")}
            </Typography>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating &&
                <div
                    ref={componentRef}
                    style={{
                        position: 'relative',
                        minHeight: 500,
                        border: '1px solid #666',
                        // backgroundColor: '#666',
                        borderRadius: 4,
                        overflow: 'hidden',
                    }}
                >
                    <DeckMapView wrapperRef={componentRef} deck={deck} spotSelectedCallback={spotSelectedCallback} mapClickCallback={mapClickCallback} />
                </div>
            }

            {!isUpdating && errorMessage &&
                <Alert severity="warning" sx={{ mt: '1rem' }}>{errorMessage}</Alert>
            }

            {!isUpdating && deck && context && context.roles && Array.isArray(context.roles) && context.roles.includes('Admin') &&
                <Link style={{ textDecoration: 'none' }} href={`/deckEditor/${deck._id}`}>
                    <IconButton aria-label="Edit Deck" title={t('Edit Deck')}>
                        <EditIcon />
                    </IconButton>
                </Link>
            }

        </>
    )
}

export default Deck
