import * as React from 'react'
import { forwardRef } from 'react'
import TaskContentView from './TaskContentView'

const TaskFormStepSummary = forwardRef((props, ref) => {
    return (
        <>
            <TaskContentView task={props.data} />
        </>
    )
})

export default TaskFormStepSummary