import { Box, Container, Typography } from '@mui/material'
import React, { useEffect, Suspense } from 'react'
import NavBar from './components/navBar/NavBar'
import './css/App.css'
import RCMRoutes from './routes'
import axios from 'axios'
import { useStoreActions } from 'easy-peasy'
import { useAuth } from 'react-oidc-context'
import { useUser } from './utils/roleHook'
import to from 'await-to-js'
import { User } from "oidc-client-ts"

const package_json = require('../package.json');

export function fetchUserInfo() {
  const url = `${window.__RUNTIME_CONFIG__.KC_URL}/protocol/openid-connect/userinfo `
  return axios.get(url, {
    headers: { authorization: getToken() }
  })
}


export function getToken() {
  const oidcStorage = localStorage.getItem(`oidc.user:${window.__RUNTIME_CONFIG__.KC_URL}:${window.__RUNTIME_CONFIG__.KC_CLIENT_ID}`)
  if (!oidcStorage) {
    return null;
  }

  return "Bearer " + User.fromStorageString(oidcStorage)?.access_token;
}


const App = () => {

  return (
    <>
      <Suspense>
        <NavBar />
        <Container maxWidth="xl">
          <Box sx={{ marginBottom: 2 }}>
            <RCMRoutes />
          </Box>
          <Typography color="primary" sx={{ textAlign: 'center', pt: '0.5rem' }}>
            {'Copyright © '}
            {new Date().getFullYear()}
          </Typography>
          {/* <Typography color="secondary"  sx={{textAlign: 'center', pt: '3rem'}}>
            {window.__RUNTIME_CONFIG__.BASE_URL}
          </Typography>*/}
          <Typography color="primary" sx={{ textAlign: 'center', pt: '1rem' }}>
            {package_json && package_json.version}
          </Typography>
          {/* <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={1000 * 10}
            open={showSnackbar}
            onClose={closeSnackbar}
            key={'top-center-error'}
            severity="error"
          >
            <Alert onClose={closeSnackbar} severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar> */}
        </Container>
      </Suspense>
    </>
  )

}

export default App