import { useTranslation } from "react-i18next"
import Icon from '@svgr-iconkit/material-community'
import { stateIcon, typeIcon } from '../../utils/icons/Icons'
import moment from 'moment'
import DeckMapView from "../decks/DeckMapView"
import { useCallback } from "react"
import { useStoreActions } from "easy-peasy"
import { useState } from "react"
import { useEffect } from "react"
import { Container } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { Typography } from "@mui/material"
import { useRef } from "react"
import QrPreview from "../qrpreview/QrPreview"
import { Grid } from "@mui/material"

const TaskContentView = (props) => {
    const { t } = useTranslation()

    const mapViewComponentRefTo = useRef()
    const mapViewComponentRefFrom = useRef()

    const [isUpdating, setIsUpdating] = useState(false)

    const [deckFrom, setDeckFrom] = useState(undefined)
    const [deckTo, setDeckTo] = useState(undefined)
    const getDeckAction = useStoreActions(actions => actions.getDeck)

    const task = props.task

    const fetchData = useCallback(async () => {
        setIsUpdating(true)
        if (props.task.from && props.task.from.deck) {
            setDeckFrom(await getDeckAction(props.task.from.deck))
        }
        if (props.task.to && props.task.to.deck) {
            setDeckTo(await getDeckAction(props.task.to.deck))
        }
        setIsUpdating(false)
    }, [getDeckAction, props.task.from, props.task.to])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {task &&
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ minWidth: 130 }}>{t('Type')}:</td>
                                <td><span style={{ display: 'inline-flex', alignItems: 'center' }}><Icon name={typeIcon(task.type).name} style={{ color: typeIcon(task.type).color, width: '1.5rem', marginRight: '0.5rem' }} /> {t(`TaskType.${task.type}`)}</span></td>
                            </tr>
                            <tr>
                                <td>{t('State')}:</td>
                                <td><span style={{ display: 'inline-flex', alignItems: 'center' }}><Icon name={stateIcon(task.state).name} style={{ color: stateIcon(task.state).color, width: '1.5rem', marginRight: '0.5rem' }} /> {t(`TaskState.${task.state}`)}</span></td>
                            </tr>
                            <tr>
                                <td style={{ paddingRight: '0.5rem' }}>{t('Target date')}:</td>
                                <td>{moment(task.dueDate).format(t("date.format") + " HH:mm")}</td>
                            </tr>
                        </tbody>
                    </table>

                    <Typography variant="subtitle1" gutterBottom component="div" sx={{ fontWeight: 'bold', mt: '1rem' }}>
                        {t('Car')}
                    </Typography>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ minWidth: 130 }}>{t('Description')}:</td>
                                <td>{task.car.name}</td>
                            </tr>
                            <tr>
                                <td>{t('Type')}:</td>
                                <td>{task.car.type}</td>
                            </tr>
                            <tr>
                                <td style={{ paddingRight: '0.5rem' }}>{t('Numberplate')}:</td>
                                <td>{task.car.numberPlate}</td>
                            </tr>
                            <tr>
                                <td>{t('VIN')}:</td>
                                <td>{task.car.vin}</td>
                            </tr>
                            <tr>
                                <td>{t('QR-Code')}:</td>
                                <td>
                                    <QrPreview item={task.car}>
                                        {task.car.qr}
                                    </QrPreview>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <Grid container spacing={2} sx={{ mb: '1rem' }}>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Typography variant="subtitle1" gutterBottom component="div" sx={{ fontWeight: 'bold', mt: '1rem' }}>
                                {t('Startort')}
                            </Typography>
                            {isUpdating &&
                                <Container align="center">
                                    <CircularProgress />
                                </Container>
                            }
                            {!isUpdating && task && task.from && deckFrom &&
                                <div ref={mapViewComponentRefFrom} style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: 300,
                                    maxWidth: '100%',
                                    maxHeight: 300,
                                    border: '1px solid #333',
                                    borderRadius: 4,
                                    overflow: 'hidden',
                                    marginTop: 0
                                }}>
                                    <DeckMapView wrapperRef={mapViewComponentRefFrom} deck={deckFrom} selectedSpot={task.from} disableSpotClick={true} />
                                </div>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Typography variant="subtitle1" gutterBottom component="div" sx={{ fontWeight: 'bold', mt: '1rem' }}>
                                {t('Zielort')}
                            </Typography>
                            {isUpdating &&
                                <Container align="center">
                                    <CircularProgress />
                                </Container>
                            }
                            {!isUpdating && task && task.to && deckTo &&
                                <div ref={mapViewComponentRefTo} style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: 300,
                                    maxWidth: '100%',
                                    maxHeight: 300,
                                    border: '1px solid #333',
                                    borderRadius: 4,
                                    overflow: 'hidden',
                                    marginTop: 0
                                }}>
                                    <DeckMapView wrapperRef={mapViewComponentRefTo} deck={deckTo} selectedSpot={task.to} disableSpotClick={true} />
                                </div>
                            }
                            {!isUpdating && !deckTo &&
                                <>
                                    Zielort beliebig
                                </>
                            }
                        </Grid>
                    </Grid>




                </div>
            }
        </>
    )

}

export default TaskContentView