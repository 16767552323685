import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CarPark from './components/carparks/CarPark'
import CarParks from './components/carparks/CarParks'
import CarHistory from './components/cars/CarHistory'
import Cars from './components/cars/Cars'
import Deck from './components/decks/Deck'
import DeckEditor from './components/decks/DeckEditor'
import Employees from './components/employees/Employees'
import Home from './components/home'
import Keylockers from './components/keylockers/Keylockers'
import Tasks from './components/tasks/Tasks'
import Tenant from './components/tenants/Tenant'
import Tenants from './components/tenants/Tenants'
import PrivateRoute from './utils/private-route'

const RCMRoutes = () => {

    return (
        <PrivateRoute>
            <Routes>

                <Route exact path="/" element={<Home />} />
                <Route exact path="/carparks" element={<CarParks />} />
                <Route exact path="/cars" element={<Cars />} />
                <Route exact path="/carpark/:carparkId" element={<CarPark />} />
                <Route exact path="/carHistory/:carId" element={<CarHistory />} />
                <Route exact path="/deck/:deckId" element={<Deck />} />
                <Route exact path="/deckEditor/:deckId" element={<DeckEditor />} />
                <Route exact path="/employees" element={<Employees />} />
                <Route exact path="/keylockers" element={<Keylockers />} />
                <Route exact path="/tasks" element={<Tasks />} />
                <Route exact path="/tenants" element={<Tenants />} />
                <Route exact path="/tenant" element={<Tenant />} />

            </Routes>
        </PrivateRoute>
    )
}

export default RCMRoutes