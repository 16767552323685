import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

import { useUser } from './roleHook';
import to from 'await-to-js';
import { fetchUserInfo } from '../App';
import { useStoreActions, useStoreState } from 'easy-peasy';
//console.log('window.__RUNTIME_CONFIG__', window.__RUNTIME_CONFIG__)
const redirectTo = (url) => {
    window.location = url
}


const PrivateRoute = (props) => {
    const auth = useAuth();
    // const user = useUser()
    const getContextAction = useStoreActions(actions => actions.getContext)
    const context = useStoreState(actions => actions.context)

    const redirectOnFirstLogin = async () => {
        if (context) {
            return
        }
        const context2 = await getContextAction()

        if (!context && context2 && context2.roles && Array.isArray(context2.roles) && (!(context2.roles.includes('ServiceManager') || context2.roles.includes('Admin')))) {
            console.log("Currently only Admins or ServiceManagers are allowed in here. You are neither. Logging you out...")
            //      logout()
            console.log('wrong role!')
            return false
        }

        if (context2.roles && Array.isArray(context2.roles) && context2.roles.includes('Admin')) {
            if (!localStorage.getItem('currentTenantId') && window.location.href.substring(window.location.href.length - 8) !== '/tenants') {
                console.log('no tenant selected')
                redirectTo('/tenants')
            }
            else {
                const url = new URL(window.location.href)
                console.log('else', url.pathname)
                if (url.pathname === '/') {
                    redirectTo('/tasks')
                }
            }
        }
    }

    const signIn = async () => {
        let originPath = window.location.pathname;
        const url = window.__RUNTIME_CONFIG__.HOST_URL
        auth.signinRedirect({
            redirect_uri: url.concat(originPath),
        });
    }

    useEffect(() => {
        if (context && context.roles && Array.isArray(context.roles) && context.roles.includes('Admin')) {
            if (!localStorage.getItem('currentTenantId') && window.location.href.substring(window.location.href.length - 8) !== '/tenants') {
                console.log('no tenant selected')
                redirectTo('/tenants')
            }
            else {
                const url = new URL(window.location.href)
                console.log('else', url.pathname)
                if (url.pathname === '/') {
                    redirectTo('/tasks')
                }
            }
        }
    }, [context])

    /*
        useEffect(() => {
            const fetch = async () => {
                const [userInfoErr, userInfo] = await to(fetchUserInfo())
                console.log('userInfoErr', userInfoErr)
                console.log('userInfo', userInfo)
                if (userInfoErr) {
                    console.log('userInfoErr', userInfoErr)
                    //signIn()
                    // return
                }
            }
            if (auth.isAuthenticated && !auth.isLoading && !auth.error && !auth.isRedirecting && !auth.activeNavigator && !auth.user.access_token) {
                console.log('useEffect auth.isAuthenticated fetch', auth.isAuthenticated)
    
                fetch()
    
            }
            if (auth.isAuthenticated && !auth.isLoading && !auth.error && !auth.isRedirecting && !auth.activeNavigator && auth.user.access_token) {
                redirectOnFirstLogin()
            }
    
    
        }, [auth.isAuthenticated, auth.activeNavigator, auth.error, auth.isLoading, auth.isRedirecting, auth.user, redirectOnFirstLogin])
    */

    useEffect(() => {
        const fetch = async () => {
            const [userInfoErr, userInfo] = await to(fetchUserInfo())
            console.log('userInfoErr', userInfoErr)
            console.log('userInfo', userInfo)
            if (!userInfoErr && userInfo) {
                //console.log('call hydrateUserResources')
                getContextAction()
                //  props.hydrateUserResources(user)
            }
            if (userInfoErr) {
                console.log('userInfoErr', userInfoErr)
                //auth.signinRedirect()
                // return
            }
        }
        console.log('auth.isAuthenticated', auth.isAuthenticated)
        if (auth.isAuthenticated && !auth.isLoading) {
            fetch()
        }

    }, [auth.isAuthenticated])



    switch (auth.activeNavigator) {
        case 'signinSilent':
            return <div>Signing you in...</div>;
        case 'signoutRedirect':
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        // <div>Loading...</div>;
        return <div></div>;
    }

    if (auth.error && !auth.isLoading) {
        console.log('auth.error', auth.error)
        signIn()
    }
    if (!auth.isAuthenticated && !auth.isLoading && !auth.error && !auth.isRedirecting && !auth.activeNavigator) {
        console.log('not authenticated', auth)
        signIn()
    }

    if (context && auth.isAuthenticated && !auth.isLoading && !auth.error && !auth.isRedirecting && !auth.activeNavigator && auth.user.access_token) {

        // props.hydrateUserResources(user)
        //props.getContext()

        window.history.replaceState({}, document.title, window.location.pathname);
        return <>{props.children}</>;
    }

};

export default PrivateRoute