import { TableContainer } from '@mui/material'
import { Table } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { Link } from '@mui/material'
import { Paper } from '@mui/material'
import { TableCell } from '@mui/material'
import { TableHead } from '@mui/material'
import { Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useStoreState, useStoreActions } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import moment from 'moment'

const Tenants = ({ navigation }) => {
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(false)

    const tenants = useStoreState(state => state.tenants)
    const getTenants = useStoreActions(actions => actions.getTenants)
    const setCurrentTenantId = useStoreActions(actions => actions.setCurrentTenantId)
    const setCurrentTenantName = useStoreActions(actions => actions.setCurrentTenantName)

    useEffect(() => {
        const fetchData = async () => {
            setIsUpdating(true)
            await getTenants()
            setIsUpdating(false)
        }
        fetchData()
    }, [getTenants])

    return (
        <>
            <Breadcrumbler levels={[{ title: t("Tenants"), url: '/tenants' }]} />

            <Typography variant="h1" component="div" gutterBottom>
                {t('Tenants')}
            </Typography>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Name')}</TableCell>
                                <TableCell align="right">{t('Created at')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tenants && Array.isArray(tenants) && tenants.map((tenant) => (
                                <TableRow
                                    key={tenant._id}
                                    sx={{
                                        verticalAlign: 'top',
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link style={{ textDecoration: 'none' }} onClick={() => { setCurrentTenantId(tenant._id); setCurrentTenantName(tenant.name) }} href={`/tasks`}>{tenant.name}</Link>
                                    </TableCell>
                                    <TableCell align="right">{moment(tenant.dueDate).format(t("date.format") + " HH:mm")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )

}

export default Tenants