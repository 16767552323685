import * as React from 'react'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Joi from 'joi'
import { Autocomplete } from 'mui-rff'
import { useStoreActions } from 'easy-peasy'
import { useStoreState } from 'easy-peasy'
import { Container } from '@mui/material'
import { CircularProgress } from '@mui/material'
import DeckMapView from '../decks/DeckMapView'
import { Radios } from 'mui-rff'
import Condition from '../../utils/formfields/Condition'
import { FormSpy } from 'react-final-form'
import { forwardRef } from 'react'
import { useRef } from 'react'
import { useCallback } from 'react'
import { useImperativeHandle } from 'react'
import { useEffect } from 'react'

const TaskFormStepFrom = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const componentRef = useRef()

    let lastValuesState = {}
    const initialValues = props.initialValues

    const [isUpdating, setIsUpdating] = React.useState(false)
    const [isUpdatingDeck, setIsUpdatingDeck] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [selectedDeck, setSelectedDeck] = React.useState(undefined)
    const [selectedSpot, setSelectedSpot] = React.useState(initialValues.selectedSpotData)

    const carparks = useStoreState(state => state.carparks)
    const deck = useStoreState(state => state.deck)
    const getCarparksAction = useStoreActions(actions => actions.getCarparks)
    const getDeckAction = useStoreActions(actions => actions.getDeck)

    if (props.initialValues && Object.keys(props.initialValues).length === 0) {
        if (props.taskDetails && props.taskDetails.type === 'park') {
            if (carparks && Array.isArray(carparks) && carparks.length > 0 && carparks[0].servicedesk) {
                initialValues.selectedStartFromOption = carparks[0].servicedesk._id
            }
        } else {
            initialValues.selectedStartFromOption = 'carLocation'
        }
    }
    if (!(props.carData && props.carData.car && props.carData.car.spot)) {
        if (carparks && Array.isArray(carparks) && carparks.length > 0 && carparks[0].servicedesk) {
            initialValues.selectedStartFromOption = carparks[0].servicedesk._id
        } else {
            initialValues.selectedStartFromOption = 'manually'
        }
    }

    const fetchData = useCallback(async () => {
        setIsUpdating(true)
        await getCarparksAction()
        setIsUpdating(false)
    }, [getCarparksAction])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    useImperativeHandle(ref, () => ({
        getValueList,
        isStepComplete
    }))

    const validationSchemaAuto = Joi.object({
        selectedStartFromOption: Joi.string().required(),
        selectedSpot: Joi.string().min(1).max(30).required(),
        selectedSpotData: Joi.object(),
    })

    const validationSchemaManual = Joi.object({
        selectedStartFromOption: Joi.string().required(),
        carpark: Joi.string().min(1).max(30).required(),
        deck: Joi.string().min(1).max(30).required(),
        selectedSpot: Joi.string().min(1).max(30).required(),
        selectedSpotData: Joi.object(),
    })

    const onSubmit = async (values) => {
        return false
    }

    const isStepComplete = () => {
        console.log("TaskFormStepFrom isStepComplete", lastValuesState)
        // @see: https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
        document.getElementById('formInner01').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        if (lastValuesState.error && lastValuesState.error !== undefined) {
            setErrorMessage(t("Please fill all the fields correctly"))
            return false
        }
        setErrorMessage("")
        return true
    }

    const getValueList = () => {
        return lastValuesState
    }

    const validate = async (values) => {
        // console.log("TaskFormStepFrom validate", values)
        lastValuesState = values
        delete lastValuesState.error

        let validationResult = undefined
        if (lastValuesState && lastValuesState.selectedStartFromOption === 'manually') {
            lastValuesState.selectedSpot = selectedSpot ? selectedSpot._id : undefined
            lastValuesState.selectedSpotData = selectedSpot
            validationResult = validationSchemaManual.validate(lastValuesState, { abortEarly: false })
        } else if (lastValuesState && lastValuesState.selectedStartFromOption === 'carLocation') {
            delete lastValuesState.carpark
            delete lastValuesState.deck
            lastValuesState.selectedSpot = props.carData.car.spot._id
            lastValuesState.selectedSpotData = props.carData.car.spot
            validationResult = validationSchemaAuto.validate(lastValuesState, { abortEarly: false })
        } else if (carparks && Array.isArray(carparks)) { // selectedStartFromOption is probably a service-desk spot-_id
            delete lastValuesState.carpark
            delete lastValuesState.deck
            for (let i = 0; i < carparks.length; i++) {
                if (carparks[i].servicedesk && carparks[i].servicedesk._id === lastValuesState.selectedStartFromOption) {
                    lastValuesState.selectedSpot = carparks[i].servicedesk._id
                    lastValuesState.selectedSpotData = carparks[i].servicedesk
                    break;
                }
            }
            validationResult = validationSchemaAuto.validate(lastValuesState, { abortEarly: false })
        }

        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
            })
            lastValuesState.error = true
            return errorMessages
        }
        lastValuesState.error = false
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    const findCarparkById = (id) => {
        if (!id) {
            return undefined
        }
        return carparks ? carparks.find(carpark => carpark._id === id) : undefined
    }

    const findDeckById = (carpark, id) => {
        if (!id) {
            return undefined
        }
        return carpark && carpark.decks ? carpark.decks.find(deck => deck._id === id) : undefined
    }

    const spotSelectedCallback = (spot) => {
        console.log("spotSelectedCallback", spot)
        setSelectedSpot(undefined)
        if (!spot) {
            return false
        }
        setErrorMessage('')
        setSelectedSpot(spot)
    }

    const onFormChange = async (form) => {
        if (!(form.values && form.values.deck)) {
            return
        } else if (selectedDeck === form.values.deck) {
            return
        }

        setSelectedDeck(form.values.deck)
        setIsUpdatingDeck(true)
        await getDeckAction(form.values.deck)
        setIsUpdatingDeck(false)
    }

    const doWeKnowTheLocationOfTheCar = () => {
        return (props.carData && props.carData.car && props.carData.car.spot)
    }

    const getRadioOptions = () => {
        const options = []

        if (carparks && Array.isArray(carparks)) {
            carparks.forEach((carpark, index) => {
                if (carpark.servicedesk && carpark.servicedesk.name) {
                    options.push({ label: carpark.name + ' / ' + carpark.servicedesk.name, value: carpark.servicedesk._id })
                }
            })
        }

        options.push({ label: t('Fahrzeugstandort') + (!doWeKnowTheLocationOfTheCar() ? ' (Standort unbekannt)' : ''), value: 'carLocation', disabled: !doWeKnowTheLocationOfTheCar() },)
        options.push({ label: t('Startort manuell wählen'), value: 'manually' })
        return options
    }

    return (
        <>
            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating && Array.isArray(carparks) &&
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validate}
                    render={({ handleSubmit, values }) => {
                        const selectedCarpark = findCarparkById(values.carpark)
                        const selectedDeck = findDeckById(selectedCarpark, values.deck)

                        return (
                            <form onSubmit={handleSubmit} id="formInner01" noValidate>
                                <FormSpy onChange={onFormChange} />
                                <Radios
                                    label={t('Startort wählen')}
                                    name="selectedStartFromOption"
                                    required={true}
                                    data={getRadioOptions()}
                                />
                                <Condition when="selectedStartFromOption" is={'manually'}>
                                    <>
                                        <Autocomplete
                                            label={t("Choose a carpark")}
                                            name="carpark"
                                            required={true}
                                            options={carparks.sort((a, b) => a.name.localeCompare(b.name))}
                                            getOptionValue={option => option._id}
                                            getOptionLabel={option => `${option.name}`}
                                            disableCloseOnSelect={false}
                                            disableClearable={true}
                                        // onChange={(e1, e2) => { console.log("onChange", e1, e2) }}
                                        // defaultValue={findCarById(selectedCarId)}
                                        /** 
                                         * [2022-07-14]
                                         * The mui-rff Autocomplete has a bug leading to an "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."-Error
                                         * @see: https://github.com/lookfirst/mui-rff/issues/308
                                         */
                                        />

                                        {selectedCarpark && selectedCarpark.decks &&
                                            <Autocomplete
                                                sx={{ mt: '1rem' }}
                                                label={t("Choose a deck")}
                                                name="deck"
                                                required={true}
                                                options={selectedCarpark.decks.sort((a, b) => a.name.localeCompare(b.name))}
                                                getOptionValue={option => option._id}
                                                getOptionLabel={option => `${option.name}`}
                                                disableCloseOnSelect={false}
                                                disableClearable={true}
                                            // defaultValue={findCarById(selectedCarId)}
                                            /** 
                                             * [2022-07-14]
                                             * The mui-rff Autocomplete has a bug leading to an "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."-Error
                                             * @see: https://github.com/lookfirst/mui-rff/issues/308
                                             */
                                            />
                                        }

                                        {selectedDeck && !isUpdatingDeck &&
                                            <div ref={componentRef} style={{
                                                display: 'block',
                                                position: 'relative',
                                                width: '100%',
                                                height: 300,
                                                maxWidth: '100%',
                                                maxHeight: 300,
                                                border: '1px solid #333',
                                                borderRadius: 4,
                                                overflow: 'hidden',
                                                marginTop: '1rem'
                                            }}>
                                                <DeckMapView wrapperRef={componentRef} deck={deck} selectedSpot={initialValues.selectedSpotData} spotSelectedCallback={spotSelectedCallback} />
                                            </div>
                                        }

                                        {isUpdatingDeck &&
                                            <Container align="center">
                                                <CircularProgress />
                                            </Container>
                                        }
                                    </>
                                </Condition>

                                {
                                    errorMessage &&
                                    <Alert severity="warning" sx={{ mt: '1rem' }}>{errorMessage}</Alert>
                                }
                            </form>
                        )
                    }}
                />
            }
        </>
    )
})

export default TaskFormStepFrom