import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Joi from 'joi'
import { useStoreActions } from 'easy-peasy'
import { Alert } from '@mui/material'
import QrScannerTextFieldMuiRff from '../../utils/formfields/QrScannerTextFieldMuiRff'
import { Field } from 'react-final-form'
import { useState } from 'react'
import { DialogContentText } from '@mui/material'

const CarQrCodeForm = (props) => {
    const { t } = useTranslation()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const resetCar = useStoreActions(actions => actions.resetCar)
    const updateCar = useStoreActions(actions => actions.updateCar)

    const onSubmit = async (values) => {
        console.log("CarQrCodeForm onSubmit", values, props)
        setErrorMessage(undefined)
        setIsSubmitting(true)
        await resetCar()

        // save
        let result = undefined
        if (props.carData && props.carData._id) {
            const valuesCopy = JSON.parse(JSON.stringify(values))
            valuesCopy._id = props.carData._id
            valuesCopy.name = props.carData.name
            valuesCopy.type = props.carData.type
            valuesCopy.numberPlate = props.carData.numberPlate
            valuesCopy.vin = props.carData.vin
            valuesCopy.customerName = props.carData.customerName
            result = await updateCar(valuesCopy)
        } else {
            setIsSubmitting(false)
            return false
        }

        console.log("onSubmit result", result)

        setIsSubmitting(false)
        if (result.error && result.error.response && result.error.response.status === 400 && result.error.response.data && result.error.response.data.code === "11000") {
            setErrorMessage(t('A car with that QR code already exists!'))
            return false
        }

        props.closeDialog(result)
    }

    const cancel = async () => {
        setIsSubmitting(false)
        props.closeDialog(undefined)
    }

    const validationSchema = Joi.object({
        qr: Joi.string().min(10).max(10).required(),
    })

    const validate = async (values) => {
        const validationResult = validationSchema.validate(values, { abortEarly: false })
        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            setIsSubmitting(false)
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                if (errorDetail.context.key) {
                    errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
                }
            })
            console.log("validationResult.error.details", validationResult.error.details, errorMessages)
            return errorMessages
        }
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    return (
        <>
            <Dialog open={true}>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({ form, handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>{t('Add Car QR-Code')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Dem gewählten Fahrzeug wurde noch kein QR-Code zugewiesen.<br />
                                    Bitte geben Sie einen neuen QR-Code für das Fahrzeug an:<br />
                                    <br />
                                </DialogContentText>
                                <Field name="qr" component={QrScannerTextFieldMuiRff} sx={{ mb: '1.5rem' }} label={t('QR-Code')} required={true} />
                                {errorMessage &&
                                    <Alert severity="warning">{errorMessage}</Alert>
                                }
                            </DialogContent>
                            <DialogActions sx={{ mb: '1rem' }} >
                                <Button onClick={() => { cancel() }} variant="outlined">{t('Cancel')}</Button>
                                <Button type="submit" disabled={isSubmitting} variant="contained">{t('form.Submit')}</Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>
        </>
    )
}

export default CarQrCodeForm