import * as React from 'react'
import { useRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Alert } from '@mui/material'
import { Stepper } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import { Step } from '@mui/material'
import { StepLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useStoreActions } from 'easy-peasy'
import TaskFormStepDetails from './TaskFormStepDetails'
import TaskFormStepCar from './TaskFormStepCar'
import TaskFormStepFrom from './TaskFormStepFrom'
import TaskFormStepTo from './TaskFormStepTo'
import TaskFormStepSummary from './TaskFormStepSummary'
import { Container } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { useEffect } from 'react'
import { useCallback } from 'react'


let subFormValues = {}

const TaskForm = (props) => {
    const { t } = useTranslation()
    const taskFormStepRefDetails = useRef()
    const taskFormStepRefCar = useRef()
    const taskFormStepRefFrom = useRef()
    const taskFormStepRefTo = useRef()
    const taskFormStepRefSummary = useRef()

    const [activeStep, setActiveStep] = React.useState(0)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const createTask = useStoreActions(actions => actions.createTask)
    const updateTask = useStoreActions(actions => actions.updateTask)

    const steps = [
        'Taskdetails',
        'Fahrzeug',
        'Startort',
        'Zielort',
        'Zusammenfassung',
    ]

    /**
     * Restores the form values from props. Runs only once on first load. Each following call does nothing.
     */
    const restoreFormValues = useCallback(async () => {
        if (subFormValues["__initialized"] === true || !(props && props.task) || !activeStep === 0) {
            return false
        }
        subFormValues["__initialized"] = true

        subFormValues["TaskFormStepDetails"] = {
            "dueDateChoice": "date",
            "dueDate": props.task.dueDate,
            "name": props.task.name,
            "type": props.task.type
        }
        subFormValues["TaskFormStepCar"] = {
            "car": props.task.car ? JSON.parse(JSON.stringify(props.task.car)) : undefined
        }
        subFormValues["TaskFormStepFrom"] = {
            "selectedStartFromOption": props.task.from ? 'no' : 'yes',
            "carpark": props.task.from && props.task.from.carpark ? props.task.from.carpark : undefined,
            "deck": props.task.from && props.task.from.deck ? props.task.from.deck : undefined,
            "selectedSpot": props.task.from ? props.task.from._id : undefined,
            "selectedSpotData": props.task.from ? JSON.parse(JSON.stringify(props.task.from)) : undefined,
        }
        subFormValues["TaskFormStepTo"] = {
            "allowArbitraryDestination": props.task.to ? 'no' : 'yes',
            "carpark": props.task.to && props.task.to.carpark ? props.task.to.carpark : undefined,
            "deck": props.task.to && props.task.to.deck ? props.task.to.deck : undefined,
            "selectedSpot": props.task.to ? props.task.to._id : undefined,
            "selectedSpotData": props.task.to ? JSON.parse(JSON.stringify(props.task.to)) : undefined,
        }
    }, [activeStep, props])

    const rememberFormValues = () => {
        switch (activeStep) {
            case 0:
                subFormValues['TaskFormStepDetails'] = taskFormStepRefDetails.current.getValueList()
                break
            case 1:
                subFormValues['TaskFormStepCar'] = taskFormStepRefCar.current.getValueList()
                break
            case 2:
                subFormValues['TaskFormStepFrom'] = taskFormStepRefFrom.current.getValueList()
                break
            case 3:
                subFormValues['TaskFormStepTo'] = taskFormStepRefTo.current.getValueList()
                break
            default:
                break
        }
    }

    const handleNext = async () => {
        rememberFormValues()

        const refs = [
            taskFormStepRefDetails,
            taskFormStepRefCar,
            taskFormStepRefFrom,
            taskFormStepRefTo,
        ]

        if ((activeStep < refs.length) && !refs[activeStep].current.isStepComplete()) {
            return false
        } else if (activeStep === refs.length) {
            const result = await saveTask()
            console.log("TaskForm.js handleNext saveTask result", result)
            return false
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
        }
    }

    const handleBack = () => {
        rememberFormValues()
        setIsSubmitting(false)
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const cancel = async (values) => {
        props.closeDialog()
        subFormValues = {}
    }

    const getSubFormValues = (subFormId) => {
        restoreFormValues()
        if (subFormValues.hasOwnProperty(subFormId)) {
            return subFormValues[subFormId]
        }
        return {}
    }

    const getPayload = () => {
        // console.log("getPayload", subFormValues)
        const payload = {
            "name": subFormValues.TaskFormStepDetails.name,
            "type": subFormValues.TaskFormStepDetails.type,
            "car": subFormValues.TaskFormStepCar.car._id,
            "dueDate": subFormValues.TaskFormStepDetails.dueDateChoice === 'date' ? subFormValues.TaskFormStepDetails.dueDate : new Date(),
        }

        if (subFormValues.TaskFormStepFrom.selectedSpot) {
            payload.from = subFormValues.TaskFormStepFrom.selectedSpot
        }
        if (subFormValues.TaskFormStepTo.selectedSpot) {
            payload.to = subFormValues.TaskFormStepTo.selectedSpot
        }

        return payload
    }

    const getSummaryData = () => {
        console.log("TaskForm getSummaryData subFormValues", subFormValues)
        const data = getPayload()
        data.state = 'open'
        data.car = subFormValues.TaskFormStepCar.car
        data.from = subFormValues.TaskFormStepFrom.selectedSpotData
        data.to = subFormValues.TaskFormStepTo.selectedSpotData
        return data
    }

    const saveTask = async () => {
        console.log("TaskForm saveTask", subFormValues)
        setIsSubmitting(true)

        const payload = getPayload()

        let result = undefined
        if (props.task) {
            payload._id = props.task._id
            result = await updateTask(payload)
        } else {
            payload.state = 'open'
            result = await createTask(payload)
        }

        setIsSubmitting(false)
        if (result.error) {
            setErrorMessage(result.message)
            return false
        }

        props.closeDialog(result._id)
        subFormValues = {}
    }

    useEffect(() => {
        restoreFormValues()
    }, [restoreFormValues])

    return (
        <>
            <Dialog
                open={true}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>{props.task ? t('Edit Task') : t('Add Task')}</DialogTitle>
                <DialogContent sx={{ minHeight: '450px' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {}
                            const labelProps = { sx: { mb: '0.5rem' } }
                            return (
                                <Step key={label} {...stepProps} sx={{ color: '#ff00ff' }}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            )
                        })}
                    </Stepper>

                    <Typography variant="h3" component="div" sx={{ mt: '1rem', mb: '1.25rem', fontSize: '1.5rem' }}>
                        {steps[activeStep]}
                    </Typography>

                    <Box sx={{ mt: '1rem' }}>
                        {activeStep === steps.length ? (
                            <>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                            </>
                        ) : (
                            <>
                                {activeStep === 0 && <TaskFormStepDetails ref={taskFormStepRefDetails} initialValues={getSubFormValues('TaskFormStepDetails')} />}
                                {activeStep === 1 && <TaskFormStepCar ref={taskFormStepRefCar} initialValues={getSubFormValues('TaskFormStepCar')} />}
                                {activeStep === 2 && <TaskFormStepFrom ref={taskFormStepRefFrom} initialValues={getSubFormValues('TaskFormStepFrom')} taskDetails={getSubFormValues('TaskFormStepDetails')} carData={getSubFormValues('TaskFormStepCar')} />}
                                {activeStep === 3 && <TaskFormStepTo ref={taskFormStepRefTo} initialValues={getSubFormValues('TaskFormStepTo')} taskDetails={getSubFormValues('TaskFormStepDetails')} taskFrom={getSubFormValues('TaskFormStepFrom')} />}
                                {activeStep === 4 && <TaskFormStepSummary ref={taskFormStepRefSummary} data={getSummaryData()} />}
                            </>
                        )}
                    </Box>

                    {errorMessage &&
                        <Alert severity="warning">{errorMessage}</Alert>
                    }

                    {isSubmitting &&
                        <Container align="center">
                            <CircularProgress />
                        </Container>
                    }
                </DialogContent>
                <DialogActions sx={{ mb: '1rem' }} >
                    <Button onClick={() => { cancel() }} disabled={isSubmitting} variant="outlined">{t('Cancel')}</Button>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        // sx={{ mr: 1 }}
                        variant="outlined"
                    >
                        {t('form.Back')}
                    </Button>
                    <Button onClick={handleNext} disabled={isSubmitting} variant="contained">
                        {activeStep === steps.length - 1 ? t('form.Submit') : t('form.Next')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TaskForm