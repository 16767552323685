import AddIcon from '@mui/icons-material/Add'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { Box } from '@mui/material'
import { Fab } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { Link } from '@mui/material'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ViewIcon from '@mui/icons-material/Visibility'
import CellTowerIcon from '@mui/icons-material/CellTower'
import Icon from '@svgr-iconkit/material-community'
import { stateIcon, typeIcon } from '../../utils/icons/Icons'
import { useStoreActions } from 'easy-peasy'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TaskForm from './TaskForm'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { DialogContent } from '@mui/material'
import { DialogContentText } from '@mui/material'
import { DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import moment from 'moment'
import 'moment/locale/de'
import { FormGroup } from '@mui/material'
import { Checkbox } from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { Grid } from '@mui/material'
import TaskContentView from './TaskContentView'
import QrScannerTextField from '../../utils/formfields/QrScannerTextField'
import QrPreview from '../qrpreview/QrPreview'

let sseEventSource = undefined

const Tasks = () => {
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showTaskForm, setShowTaskForm] = useState(false)
    const [showViewTaskDialog, setShowViewTaskDialog] = useState(false)
    const [showDeleteTaskDialog, setShowDeleteTaskDialog] = useState(false)
    const [fetchClosedTasks, setFetchClosedTasks] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [targetTask, setTargetTask] = useState(undefined)
    const [liveSseViewEnabled, setLiveSseViewEnabled] = useState(true)
    const [tasks, setTasks] = useState(undefined)
    const [filteredTasks, setFilteredTasks] = useState(undefined)

    const getTaskSseAllEventSource = useStoreActions(actions => actions.getTaskSseAllEventSource)
    const getTasksAction = useStoreActions(actions => actions.getTasks)
    const deleteTaskAction = useStoreActions(actions => actions.deleteTask)



    const filterTasks = useCallback((tasksData, searchTerm) => {
        if (!tasksData) {
            return false
        }
        if (!searchTerm) {
            setFilteredTasks(tasksData)
            return false
        }
        setFilteredTasks(tasksData.filter((item) => {
            let haystack = item.car ? item.car.name + item.car.type + item.car.numberPlate + item.car.vin + item.car.qr + item.car.customerName : ''
            haystack += item.numberPlate ? item.numberPlate.search.replaceAll(" ", "").replaceAll("-", "") : ''
            haystack += item.user ? item.user.role + item.user.email + item.user.firstName + item.user.lastName : ''
            haystack += item.from ? item.from.name + item.from.qr : ''
            haystack += item.to ? item.to.name + item.to.qr : ''
            return haystack.toLowerCase().indexOf(searchTerm.toLowerCase(), 0) !== -1
        }))
    }, [])

    const onMessage = useCallback((e) => {
        setTasks(JSON.parse(e.data))
        filterTasks(JSON.parse(e.data), searchTerm)

    }, [setTasks, filterTasks, searchTerm])


    const sseStop = useCallback(() => {
        if (sseEventSource) {
            sseEventSource.removeEventListener('message', onMessage)
            sseEventSource.onmessage = undefined
            sseEventSource.close()
            sseEventSource = undefined
        }
    }, [onMessage])



    const sseStart = useCallback(() => {
        if (sseEventSource) {
            sseEventSource.close()
        }
        sseEventSource = getTaskSseAllEventSource()

        sseEventSource.addEventListener('message', onMessage)
        //return sseEventSource.removeEventListener('message', onMessage)
    }, [getTaskSseAllEventSource, onMessage])

    const fetchData = useCallback(async (getClosedTasks = false) => {
        setIsUpdating(true)

        // The backend endpoint getTasksAction talks to only supports filtering by task-name currently.
        // This is useless as we're not even showing the name-field anymore.
        // That's why we fetch all and do the filtering client-side for now...

        const taskData = await getTasksAction({ fetchClosedTasks: getClosedTasks })
        setTasks(taskData)
        filterTasks(taskData, searchTerm)
        setIsUpdating(false)
    }, [getTasksAction, filterTasks, searchTerm])

    useEffect(() => {
        fetchData(fetchClosedTasks)
        sseStart()
        // eslint-disable-next-line
    }, [])

    const deleteTask = async () => {
        if (!((targetTask && targetTask._id))) {
            throw new Error("targetTask is invalid!")
        }

        setIsDeleting(true)
        await deleteTaskAction(targetTask._id)
        setShowDeleteTaskDialog(false)
        setTargetTask(undefined)
        setIsDeleting(false)
        fetchData(fetchClosedTasks)
    }

    const daysUntil = (dueDate) => {
        return new moment().locale('de').to(moment(dueDate))
    }
    const getRowColorByDueDate = (dueDate) => {
        const hours = new moment(dueDate).diff(new moment(), "hours")
        if (hours >= 24) {
            return '#ffffff'
        } else if (hours >= 1) {
            return '#ffae00'
        }
        return '#d5001c'
    }

    const onClickLiveSse = (e) => {
        setLiveSseViewEnabled(e.target.checked)
        if (e.target.checked) {
            sseStart()
            setFetchClosedTasks(false)
            fetchData(false)
        } else {
            sseStop()
        }
    }

    const searchFieldOnChange = (e) => {
        setSearchTerm(e.target.value)
        setLiveSseViewEnabled(false)
        sseStop()
        filterTasks(tasks, e.target.value)
    }

    const fetchClosedTasksOnChange = (e) => {
        setFetchClosedTasks(e.target.checked)
        setLiveSseViewEnabled(!e.target.checked)
        if (!e.target.checked) {
            sseStart()
        } else {
            sseStop()
        }
        fetchData(e.target.checked)
    }

    const renderTask = (task) => {
        return (<TableRow
            key={task._id}
            sx={{
                verticalAlign: 'top',
                '&:last-child td, &:last-child th': { border: 0 }
            }}
        >
            <TableCell component="th" scope="row" sx={{
                backgroundColor: ['closed', 'ended', 'locked'].includes(task.state) ? '#33cf29' : getRowColorByDueDate(task.dueDate),
                padding: 0,
                width: 10
            }}></TableCell>
            <TableCell component="th" scope="row">
                <Box sx={{ textAlign: 'center' }}>
                    <Icon title={task.type} name={typeIcon(task.type).name} style={{ color: typeIcon(task.type).color, width: '1.5rem' }} />
                    <div style={{ fontSize: 10, marginTop: 0 }}>{t(`TaskType.${task.type}`)}</div>
                </Box>
            </TableCell>
            <TableCell component="th" scope="row">
                <Box sx={{ textAlign: 'center' }}>
                    <Icon name={stateIcon(task.state).name} style={{ color: stateIcon(task.state).color, width: '1.5rem' }} />
                    <div style={{ fontSize: 10, marginTop: 0 }}>{t(`TaskState.${task.state}`)}</div>
                </Box>
            </TableCell>
            <TableCell component="th" scope="row">
                {task.user ? task.user.firstName + ' ' + task.user.lastName : '---'}
            </TableCell>
            <TableCell component="th" scope="row">
                {task.car && task.car.customerName ? task.car.customerName : '---'}
            </TableCell>
            <TableCell component="th" scope="row">
                {(task.car && task.car.name) ? <>{task.car.name}<br /></> : ''}
                {(task.car && task.car.type) ? <>{task.car.type}<br /></> : ''}
            </TableCell>
            <TableCell component="th" scope="row">
                Kennzeichen: {(task.car && task.car.vin) ? <Link style={{ textDecoration: 'none' }} href={`/carpark/${task._id}`}>{task.car && task.car.numberPlate}</Link> : '---'}<br />
                VIN: {(task.car && task.car.vin) ? task.car.vin : '---'}<br />
                QR: {(task.car && task.car.qr) ? <QrPreview item={task.car}>{task.car && task.car.qr}</QrPreview> : '---'}
            </TableCell>
            <TableCell>{moment(task.dueDate).format(t("date.format") + " HH:mm")}</TableCell>
            <TableCell>{daysUntil(task.dueDate)}</TableCell>
            <TableCell align="right" style={{ whiteSpace: 'pre' }}>
                <IconButton onClick={() => { setTargetTask(task); setShowViewTaskDialog(true) }} aria-label="view" title={t('View Task')}>
                    <ViewIcon />
                </IconButton>
                <IconButton onClick={() => { setTargetTask(task); setShowTaskForm(true) }} aria-label="edit" title={t('Edit Task')} disabled={task.state !== 'open'}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => { setTargetTask(task); setShowDeleteTaskDialog(true) }} aria-label="delete" title={t('Delete Task')}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>)
    }


    return (
        <>
            <Breadcrumbler levels={[{ title: t("Tasks"), url: '/tasks' }]} />

            <Typography variant="h1" component="div" sx={{ mb: '1rem' }}>
                {t("Tasks")}
            </Typography>

            <Grid container spacing={2} sx={{ mb: '1rem' }}>
                <Grid item xs={12} sm={12} lg={4}>
                    <QrScannerTextField
                        label="Suche"
                        id="outlined-size-small"
                        size="small"
                        fullWidth={true}
                        placeholder="Suche nach Fahrzeug, Kennzeichen, VIN, QR-Code, ..."
                        focused
                        onChange={searchFieldOnChange}
                    ></QrScannerTextField>
                </Grid>

                <Grid item xs={10} sm={10} lg={6}>
                    <FormGroup sx={{ flexDirection: 'row' }}>
                        <FormControlLabel control={<Checkbox onClick={onClickLiveSse} checked={liveSseViewEnabled} />} label={
                            <Box sx={{ color: liveSseViewEnabled ? '#d5001c' : '#313639' }}>
                                <CellTowerIcon sx={{ fontSize: '1.5rem', verticalAlign: 'text-bottom' }} /> Live
                            </Box>
                        } />
                        <FormControlLabel control={<Checkbox onClick={(e) => (fetchClosedTasksOnChange(e))} checked={fetchClosedTasks} />} label="Abgeschlossene Tasks anzeigen" />
                    </FormGroup>
                </Grid>

                <Grid item xs={2} sm={2} lg={2} textAlign='right'>
                    <Fab color="primary" aria-label="add" size="small" title={t("Add Task")} onClick={() => { setTargetTask(undefined); setShowTaskForm(true) }}>
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating &&
                <>
                    {filteredTasks && filteredTasks.length === 0 &&
                        <Box>
                            --- Keine Tasks ---
                        </Box>
                    }
                    {filteredTasks && filteredTasks.length > 0 &&
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ padding: 0, width: 10 }}></TableCell>
                                        <TableCell align='center'>{t('Type')}</TableCell>
                                        <TableCell align='center'>{t('State')}</TableCell>
                                        <TableCell>{t('Employee')}</TableCell>
                                        <TableCell>{t('Customer')}</TableCell>
                                        <TableCell>{t('Car')}</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>{t('Target date')}</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredTasks && Array.isArray(filteredTasks) && filteredTasks.map((task) => (
                                        renderTask(task)
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }

                    <Dialog
                        open={showDeleteTaskDialog}
                        fullWidth={true}
                        maxWidth={'md'}
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t('Delete Task')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" sx={{ pb: '2rem' }}>
                                {t('Do you really want to delete this Task?')}<br />
                            </DialogContentText>
                            {showDeleteTaskDialog && <TaskContentView task={targetTask} />}
                            {isDeleting &&
                                <Container align="center">
                                    <CircularProgress />
                                </Container>
                            }
                        </DialogContent>
                        <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                            <Button onClick={() => { setShowDeleteTaskDialog(false) }} autoFocus disabled={isDeleting}>{t('Cancel')}</Button>
                            <Button onClick={() => { deleteTask() }} variant="contained" color="error" disabled={isDeleting}>{t('Delete Task')}</Button>
                        </DialogActions>
                    </Dialog>


                    <Dialog
                        open={showViewTaskDialog}
                        fullWidth={true}
                        maxWidth={'md'}
                    >
                        <DialogTitle sx={{ pb: 0 }}>
                            {t('Task')}
                        </DialogTitle>
                        <DialogContent>
                            {showViewTaskDialog && <TaskContentView task={targetTask} />}
                        </DialogContent>
                        <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                            <Button onClick={() => { setShowViewTaskDialog(false) }} autoFocus variant="contained">{t('Close')}</Button>
                        </DialogActions>
                    </Dialog>
                </>
            }

            {showTaskForm &&
                <TaskForm task={targetTask} closeDialog={() => { setShowTaskForm(false); setTargetTask(undefined); fetchData(fetchClosedTasks) }} />
            }
        </>
    )
}
export default Tasks
