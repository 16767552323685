import * as React from 'react'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Joi from 'joi'
import { Autocomplete } from 'mui-rff'
import { useStoreActions } from 'easy-peasy'
import { useStoreState } from 'easy-peasy'
import { Container } from '@mui/material'
import { CircularProgress } from '@mui/material'
import DeckMapView from '../decks/DeckMapView'
import { Radios } from 'mui-rff'
import Condition from '../../utils/formfields/Condition'
import { FormSpy } from 'react-final-form'
import { forwardRef } from 'react'
import { useRef } from 'react'

const TaskFormStepTo = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const componentRef = useRef()

    let lastValuesState = {}
    const initialValues = props.initialValues

    const [isUpdating, setIsUpdating] = React.useState(false)
    const [isUpdatingDeck, setIsUpdatingDeck] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [selectedDeck, setSelectedDeck] = React.useState(undefined)
    const [selectedSpot, setSelectedSpot] = React.useState(initialValues.selectedSpotData)

    const carparks = useStoreState(state => state.carparks)
    const deck = useStoreState(state => state.deck)
    const getCarparksAction = useStoreActions(actions => actions.getCarparks)
    const getDeckAction = useStoreActions(actions => actions.getDeck)

    if (props.initialValues && Object.keys(props.initialValues).length === 0) {
        if (props.taskDetails && (props.taskDetails.type === 'return' || props.taskDetails.type === 'extern')) {
            if (carparks && Array.isArray(carparks) && carparks.length > 0 && carparks[0].servicedesk) {
                initialValues.allowArbitraryDestination = carparks[0].servicedesk._id
            }
        } else {
            initialValues.allowArbitraryDestination = 'yes'
        }
    }

    const fetchData = React.useCallback(async () => {
        setIsUpdating(true)
        await getCarparksAction()
        setIsUpdating(false)
    }, [getCarparksAction])

    React.useEffect(() => {
        fetchData()
    }, [fetchData])


    React.useImperativeHandle(ref, () => ({
        getValueList,
        isStepComplete
    }))

    const validationSchemaWithArbitraryDestinationAllowed = Joi.object({
        allowArbitraryDestination: Joi.string().required(),
    })

    const validationSchemaWithArbitraryDestinationNotAllowed = Joi.object({
        allowArbitraryDestination: Joi.string().required(),
        carpark: Joi.string().min(1).max(30).required(),
        deck: Joi.string().min(1).max(30).required(),
        selectedSpot: Joi.string().min(1).max(30).required(),
        selectedSpotData: Joi.object(),
        //category: Joi.string().valid('ServiceManager', 'ServiceOperator', 'Viewer').required()
    })


    const onSubmit = async (values) => {
        return false
    }
    const isStepComplete = () => {
        console.log("TaskFormStepTo isStepComplete", lastValuesState)
        // @see: https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
        document.getElementById('formInner01').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))

        if (selectedSpot && selectedSpot.reserved && selectedSpot.reserved._id) {
            setErrorMessage(t("Der Zielort ist reserviert. Bitte wählen Sie einen anderen Stellplatz."))
            return false
        } else if (props.taskFrom && props.taskFrom.selectedSpot && selectedSpot && props.taskFrom.selectedSpot === selectedSpot._id) {
            setErrorMessage(t("Start- und Zielort müssen unterschiedlich sein!"))
            return false
        } else if (lastValuesState.error && lastValuesState.error !== undefined) {
            setErrorMessage(t("Please fill all the fields correctly"))
            return false
        }
        setErrorMessage("")
        return true
    }

    const getValueList = () => {
        return lastValuesState
    }

    const validate = async (values) => {
        // console.log("TaskFormStepDetails validate", values)
        lastValuesState = values
        delete lastValuesState.error

        let validationResult = undefined
        if (lastValuesState && lastValuesState.allowArbitraryDestination === 'no') {
            lastValuesState.selectedSpot = selectedSpot ? selectedSpot._id : undefined
            lastValuesState.selectedSpotData = selectedSpot
            validationResult = validationSchemaWithArbitraryDestinationNotAllowed.validate(lastValuesState, { abortEarly: false })
        } else if (lastValuesState && lastValuesState.allowArbitraryDestination === 'yes') {
            delete lastValuesState.carpark
            delete lastValuesState.deck
            delete lastValuesState.selectedSpot
            delete lastValuesState.selectedSpotData
            validationResult = validationSchemaWithArbitraryDestinationAllowed.validate(lastValuesState, { abortEarly: false })
        } else if (carparks && Array.isArray(carparks)) { // allowArbitraryDestination is probably a service-desk spot-_id
            for (let i = 0; i < carparks.length; i++) {
                if (carparks[i].servicedesk && carparks[i].servicedesk._id === lastValuesState.allowArbitraryDestination) {
                    lastValuesState.carpark = carparks[i].servicedesk.carpark
                    lastValuesState.deck = carparks[i].servicedesk.deck
                    lastValuesState.selectedSpot = carparks[i].servicedesk._id
                    lastValuesState.selectedSpotData = carparks[i].servicedesk
                    break;
                }
            }
            validationResult = validationSchemaWithArbitraryDestinationNotAllowed.validate(lastValuesState, { abortEarly: false })
        }

        console.log('validationResult.error', validationResult.error)
        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
            })
            lastValuesState.error = true
            return errorMessages
        }
        lastValuesState.error = false
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    const findCarparkById = (id) => {
        if (!id) {
            return undefined
        }
        return carparks ? carparks.find(carpark => carpark._id === id) : undefined
    }

    const findDeckById = (carpark, id) => {
        if (!id) {
            return undefined
        }
        return carpark && carpark.decks ? carpark.decks.find(deck => deck._id === id) : undefined
    }

    const spotSelectedCallback = (spot) => {
        setSelectedSpot(undefined)
        setErrorMessage('')

        if (!spot) {
            return false
        } else if (spot.car) {
            setErrorMessage(t("Der Platz ist bereits belegt."))
            return false
        }

        setSelectedSpot(spot)
    }

    const onFormChange = async (form) => {
        if (!(form.values && form.values.deck)) {
            return
        } else if (selectedDeck === form.values.deck) {
            return
        }

        setSelectedDeck(form.values.deck)
        setIsUpdatingDeck(true)
        await getDeckAction(form.values.deck)
        setIsUpdatingDeck(false)
    }

    const getRadioOptions = () => {
        const options = [
            { label: t('Zielort beliebig'), value: 'yes' },
        ]

        if (carparks && Array.isArray(carparks)) {
            carparks.forEach((carpark, index) => {
                if (carpark.servicedesk && carpark.servicedesk.name) {
                    const disabled = (props.taskDetails && props.taskDetails.type === 'park')
                    options.push({ label: carpark.name + ' / ' + carpark.servicedesk.name, value: carpark.servicedesk._id, disabled: disabled })

                    // // super messy way to pre-select the service-desk in case of a task of type return
                    // // usually there is only one service-desk per carpark, so it's not that bad.
                    // // just ugly.
                    // console.log("props.initialValues", props.taskDetails.type)
                    // if (props.initialValues && Object.keys(props.initialValues).length === 0 && props.taskDetails && props.taskDetails.type === 'return') {
                    //     initialValues.allowArbitraryDestination = carpark.servicedesk._id
                    // }
                }
            })
        }

        options.push({ label: t('Zielort festlegen'), value: 'no' })
        return options
    }

    return (
        <div>
            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating && Array.isArray(carparks) &&
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validate}
                    render={({ handleSubmit, values }) => {
                        const selectedCarpark = findCarparkById(values.carpark)
                        const selectedDeck = findDeckById(selectedCarpark, values.deck)

                        return (
                            <form onSubmit={handleSubmit} id="formInner01" noValidate>
                                <FormSpy onChange={onFormChange} />
                                <Radios
                                    label={t('Zielstandort wählen')}
                                    name="allowArbitraryDestination"
                                    required={true}
                                    data={getRadioOptions()}
                                />
                                <Condition when="allowArbitraryDestination" is={'no'}>
                                    <>
                                        <Autocomplete
                                            label={t("Choose a carpark")}
                                            name="carpark"
                                            required={true}
                                            options={carparks.sort((a, b) => a.name.localeCompare(b.name))}
                                            getOptionValue={option => option._id}
                                            getOptionLabel={option => `${option.name}`}
                                            disableCloseOnSelect={false}
                                            disableClearable={true}
                                        // onChange={(e1, e2) => { console.log("onChange", e1, e2) }}
                                        // defaultValue={findCarById(selectedCarId)}
                                        /** 
                                         * [2022-07-14]
                                         * The mui-rff Autocomplete has a bug leading to an "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."-Error
                                         * @see: https://github.com/lookfirst/mui-rff/issues/308
                                         */
                                        />

                                        {selectedCarpark && selectedCarpark.decks &&
                                            <Autocomplete
                                                sx={{ mt: '1rem' }}
                                                label={t("Choose a deck")}
                                                name="deck"
                                                required={true}
                                                options={selectedCarpark.decks.sort((a, b) => a.name.localeCompare(b.name))}
                                                getOptionValue={option => option._id}
                                                getOptionLabel={option => `${option.name}`}
                                                disableCloseOnSelect={false}
                                                disableClearable={true}
                                            // defaultValue={findCarById(selectedCarId)}
                                            /** 
                                             * [2022-07-14]
                                             * The mui-rff Autocomplete has a bug leading to an "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."-Error
                                             * @see: https://github.com/lookfirst/mui-rff/issues/308
                                             */
                                            />
                                        }

                                        {selectedDeck && !isUpdatingDeck &&
                                            <div ref={componentRef} style={{
                                                position: 'relative',
                                                width: '100%',
                                                height: 300,
                                                maxWidth: '100%',
                                                maxHeight: 300,
                                                border: '1px solid #333',
                                                borderRadius: 4,
                                                overflow: 'hidden',
                                                marginTop: '1rem'
                                            }}>
                                                <DeckMapView wrapperRef={componentRef} deck={deck} selectedSpot={lastValuesState.selectedSpotData} spotSelectedCallback={spotSelectedCallback} />
                                            </div>
                                        }
                                        {isUpdatingDeck &&
                                            <Container align="center">
                                                <CircularProgress />
                                            </Container>
                                        }
                                    </>
                                </Condition>

                                {errorMessage &&
                                    <Alert severity="warning" sx={{ mt: '1rem' }}>{errorMessage}</Alert>
                                }
                            </form>
                        )
                    }}
                />
            }
        </div>
    )
})

export default TaskFormStepTo