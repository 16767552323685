import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow, Box } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import { Pagination } from '@mui/material'
import Icon from '@svgr-iconkit/material-community'
import { typeIcon } from '../../utils/icons/Icons'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { DialogContent } from '@mui/material'
import { Button } from '@mui/material'
import { DialogActions } from '@mui/material'
import { IconButton } from '@mui/material'
import FeedIcon from '@mui/icons-material/Feed'
import TextField from '@mui/material/TextField'

const CarHistory = () => {
    const { t } = useTranslation()
    const itemsPerPage = 10

    const [isUpdating, setIsUpdating] = useState(false)
    const [showEntryDataDialog, setShowEntryDataDialog] = useState(false)
    const [targetEntry, setTargetEntry] = useState(false)
    const [pageNr, setPageNr] = useState(1)

    const historyData = useStoreState(state => state.historyData)
    const getHistoryAction = useStoreActions(actions => actions.getHistory)

    const { carId } = useParams()

    const fetchData = useCallback(async (carId, pageNr, itemsPerPage) => {
        setIsUpdating(true)
        await getHistoryAction({ carId: carId, page: pageNr, limit: itemsPerPage })
        setIsUpdating(false)
    }, [getHistoryAction])

    useEffect(() => {
        fetchData(carId, pageNr - 1, itemsPerPage)
        // eslint-disable-next-line
    }, [])

    const onPaginationChange = (e, newPageNr) => {
        setPageNr(newPageNr)
        fetchData(carId, newPageNr - 1, itemsPerPage)
    }

    return (
        <>
            <Breadcrumbler levels={[{ title: t("Cars"), url: '/cars' }, { title: t("Car History"), url: '/carHistory/' + carId }]} />

            <Typography variant="h1" component="div" gutterBottom>
                {t("Car History")}
            </Typography>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating && historyData && historyData.data && Array.isArray(historyData.data) && historyData.data.length === 0 &&
                <Box sx={{ pb: '2rem', pt: '2rem' }}>
                    Zu diesem Fahrzeug existieren keine historischen Daten
                </Box>
            }

            {!isUpdating && historyData && historyData.data && Array.isArray(historyData.data) && historyData.data.length > 0 &&
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t('history.Model')}</TableCell>
                                    <TableCell>{t('history.Action')}</TableCell>
                                    <TableCell>{t('history.User')}</TableCell>
                                    <TableCell align='center'>{t('history.Task')}</TableCell>
                                    <TableCell>{t('history.Keylocker')}</TableCell>
                                    <TableCell>{t('history.Data')}</TableCell>
                                    <TableCell align="right" style={{ whiteSpace: 'pre' }}>{t('Updated at')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {historyData.data.map((entry, index) => (
                                    <TableRow
                                        key={entry._id}
                                        sx={{
                                            verticalAlign: 'top',
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {((pageNr - 1) * itemsPerPage) + index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {entry.model && t(`history.${entry.model}`)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {entry.action && t(`history.${entry.action}`)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {entry.user && entry.user.firstName + ' ' + entry.user.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {entry.task ?
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <Icon title={entry.task.type} name={typeIcon(entry.task.type).name} style={{ color: typeIcon(entry.task.type).color, width: '2rem' }} />
                                                    <div style={{ fontSize: 10, marginTop: 0 }}>{t(`TaskType.${entry.task.type}`)}</div>
                                                </Box> : <Box sx={{ textAlign: 'center' }}>-</Box>}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {entry.keylocker ? `${entry.keylocker.name}\nFach: ${entry.keyhook?.nr || ""}` : "-"}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton onClick={() => { setTargetEntry(entry); setShowEntryDataDialog(true) }} title={t('Datensatz anzeigen')}>
                                                <FeedIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="right">{moment(entry.createdAt).format(t("date.format") + " HH:mm")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box display='flex' justifyContent='center' sx={{ pt: '1rem' }}>
                        <Pagination onChange={onPaginationChange} page={pageNr} count={(historyData && historyData.pagination && historyData.pagination.total) ? (Math.floor(historyData.pagination.total / itemsPerPage) + 1) : 1} siblingCount={1} boundaryCount={1} showFirstButton showLastButton />
                    </Box>
                </>
            }

            <Dialog
                open={showEntryDataDialog}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <DialogTitle>
                    {t('Fahrzeughistorie Datensatz')}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        multiline={true}
                        fullWidth={true}
                        rows={10}
                        disabled={true}
                        defaultValue={JSON.stringify(targetEntry, undefined, "\t")}
                    />
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowEntryDataDialog(false); setTargetEntry(undefined) }} autoFocus variant="outlined">{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CarHistory
