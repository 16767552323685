import { Fab, Grid, Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import moment from 'moment'
import { Box } from '@mui/material'
import QrPreview from '../qrpreview/QrPreview'
import CsvDownloader from 'react-csv-downloader'
import DownloadIcon from '@mui/icons-material/Download'

const Keylockers = () => {
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(false)
    const [isExportingCsv, setIsExportingCsv] = useState(false)

    const keylockers = useStoreState(state => state.keylockers)
    const getCarparksAction = useStoreActions(actions => actions.getCarparks)
    const getKeylockersAction = useStoreActions(actions => actions.getKeylockers)

    useEffect(() => {
        const fetchData = async () => {
            setIsUpdating(true)
            await getKeylockersAction()
            setIsUpdating(false)
        }
        fetchData()
    }, [getKeylockersAction])

    const handleCsvExport = async () => {
        console.log("handleCsvExport")
        if (!keylockers || isExportingCsv) {
            return false
        }
        setIsExportingCsv(true)

        const carparks = await getCarparksAction()
        const mapCarparkIdsToCarparks = []
        const mapDeckIdsToDecks = []
        carparks.forEach(carpark => {
            mapCarparkIdsToCarparks[carpark._id] = carpark
            if (!carpark.decks) {
                return false
            }
            carpark.decks.forEach(deck => {
                mapDeckIdsToDecks[deck._id] = deck
            })
        })

        const dataForExport = []
        for (let i = 0; i < keylockers.length; i++) {
            const keylocker = keylockers[i]
            let carpark = undefined
            let deck = undefined
            if (keylocker.carpark) {
                carpark = mapCarparkIdsToCarparks[keylocker.carpark]
            }
            if (keylocker.deck) {
                deck = mapDeckIdsToDecks[keylocker.deck]
            }
            for (let j = 0; j < keylocker.keyhooks.length; j++) {
                const keyhook = keylocker.keyhooks[j]
                dataForExport.push({
                    Carpark: carpark ? carpark.name : '',
                    Deck: deck ? deck.name : '',
                    KeylockerName: keylocker.name,
                    KeylockerQr: keylocker.qr,
                    KeyhookNr: keyhook.nr,
                    UpdatedAt: moment(keyhook.updatedAt).format(t("date.format") + " HH:mm"),
                    CarName: keyhook.car ? keyhook.car.name : '',
                    CarType: keyhook.car ? keyhook.car.type : '',
                    CarNumberPlate: keyhook.car ? keyhook.car.numberPlate : '',
                    CarCustomerName: keyhook.car ? keyhook.car.customerName : '',
                    CarVIN: keyhook.car ? keyhook.car.vin : '',
                    CarQR: keyhook.car ? keyhook.car.qr : '',                
                })
            }
        }

        setIsExportingCsv(false)
        return dataForExport
    }

    return (
        <>
            <Breadcrumbler levels={[{ title: t("Keylocker"), url: '/keylockers' }]} />

            <Grid container spacing={2} sx={{ mb: '0.35rem' }}>
                <Grid item xs={8} sm={8} lg={10}>
                <Typography variant="h1" component="div" gutterBottom>
                {t("Keylocker")}
            </Typography>
                </Grid>
                <Grid item xs={4} sm={4} lg={2} textAlign='right'>
                    <CsvDownloader
                        filename="rcm-keylockers"
                        suffix={moment().format("YYYY-MM-DD-HH[h]mm[m]ss[s]")}
                        extension=".csv"
                        separator=";"
                        wrapColumnChar=""
                        style={{ display: 'inline-block' }}
                        datas={handleCsvExport}
                    >
                        <Fab color="primary" size="small" title={t("CSV-Export")}>
                            {isExportingCsv &&
                                <Box align="center" style={{ padding: '5px 0 0 0' }}>
                                    <CircularProgress size={200} color='inherit' />
                                </Box>
                            }
                            {!isExportingCsv &&
                                <DownloadIcon />
                            }
                        </Fab>
                    </CsvDownloader>
                </Grid>
            </Grid>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating && keylockers && Array.isArray(keylockers) && keylockers.sort((a, b) => a.name.localeCompare(b.name)).map((keylocker) => (
                <Box key={'kh-' + keylocker._id}>
                    <Typography variant="h6" component="div" gutterBottom>
                         <QrPreview item={keylocker}>{keylocker.name}</QrPreview>
                    </Typography>
                    <TableContainer key={keylocker._id} component={Paper} style={{ marginBottom: '2rem' }}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Nr.')}</TableCell>
                                    <TableCell>{t('Name')}</TableCell>
                                    <TableCell>{t('Type')}</TableCell>
                                    <TableCell>{t('Numberplate')}</TableCell>
                                    <TableCell>{t('VIN')}</TableCell>
                                    <TableCell>{t('QR-Code')}</TableCell>
                                    <TableCell align="right">{t('Updated at')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {keylocker.keyhooks && Array.isArray(keylocker.keyhooks) && keylocker.keyhooks.length === 0 &&
                                    <TableRow>
                                        <TableCell>---</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                }
                                {keylocker.keyhooks && Array.isArray(keylocker.keyhooks) && keylocker.keyhooks.length > 0 && keylocker.keyhooks.sort((a, b) => { return a.nr < b.nr ? -1 : 1 }).map((keyhook) => (
                                    <TableRow
                                        key={'kh-' + keylocker._id + '-' + keyhook._id}
                                        sx={{
                                            verticalAlign: 'top',
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        {keyhook.car &&
                                            <>
                                                <TableCell>
                                                    {keyhook.nr}
                                                </TableCell>
                                                <TableCell>
                                                    {keyhook.car.name}
                                                </TableCell>
                                                <TableCell>
                                                    {keyhook.car.type}
                                                </TableCell>
                                                <TableCell>
                                                    {keyhook.car.numberPlate}
                                                </TableCell>
                                                <TableCell>
                                                    {keyhook.car.vin}
                                                </TableCell>
                                                <TableCell>
                                                    {keyhook.car.qr}
                                                </TableCell>
                                                <TableCell align="right">{moment(keylocker.updatedAt).format(t("date.format") + " HH:mm")}</TableCell>
                                            </>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}
        </>
    );
};
export default Keylockers
