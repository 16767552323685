import { Box, Fab, Grid, Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { Link } from '@mui/material'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import CsvDownloader from 'react-csv-downloader'
import DownloadIcon from '@mui/icons-material/Download'

const CarPark = () => {
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(false)
    const [isExportingCsv, setIsExportingCsv] = useState(false)

    const carpark = useStoreState(state => state.carpark)
    const getCarpark = useStoreActions(actions => actions.getCarpark)
    const getDeck = useStoreActions(actions => actions.getDeck)

    const { carparkId } = useParams()

    useEffect(() => {
        const fetchData = async () => {
            setIsUpdating(true)
            await getCarpark(carparkId)
            setIsUpdating(false)
        }
        fetchData()
    }, [carparkId, getCarpark])


    const handleCsvExport = async () => {
        console.log("handleCsvExport")
        if (!carpark.decks || isExportingCsv) {
            return false
        }
        setIsExportingCsv(true)

        const mapDeckIdsToDecks = {}
        for (let i = 0; i < carpark.decks.length; i++) {
            const deck = carpark.decks[i]
            mapDeckIdsToDecks[deck._id] = await getDeck(deck._id)
        }

        const dataForExport = []
        for (let i = 0; i < carpark.decks.length; i++) {
            const deck = mapDeckIdsToDecks[carpark.decks[i]._id]
            for (let j = 0; j < deck.spots.length; j++) {
                const spot = deck.spots[j]
                dataForExport.push({
                    CarparkName: carpark.name,
                    DeckName: deck.name,
                    SpotName: spot.name,
                    SpotQr: spot.qr,
                    SpotType: spot.type,
                    CarName: spot.car ? spot.car.name : '',
                    CarType: spot.car ? spot.car.type : '',
                    CarNumberPlate: spot.car ? spot.car.numberPlate : '',
                    CarCustomerName: spot.car ? spot.car.customerName : '',
                    CarVIN: spot.car ? spot.car.vin : '',
                    CarQR: spot.car ? spot.car.qr : '',
                })
            }
        }
        setIsExportingCsv(false)
        return dataForExport
    }

    return (
        <>
            <Breadcrumbler levels={[{ title: t("Car parks"), url: '/carparks' }, { title: carpark && carpark.name, url: '/carpark/' + carparkId }]} />

            <Grid container spacing={2} sx={{ mb: '0.35rem' }}>
                <Grid item xs={8} sm={8} lg={10}>
                    <Typography variant="h1" component="div" gutterBottom>
                        {t("Car park")} {carpark && carpark.name}
                    </Typography>
                </Grid>
                <Grid item xs={4} sm={4} lg={2} textAlign='right'>
                    <CsvDownloader
                        filename="rcm-spots"
                        suffix={moment().format("YYYY-MM-DD-HH[h]mm[m]ss[s]")}
                        extension=".csv"
                        separator=";"
                        wrapColumnChar=""
                        style={{ display: 'inline-block' }}
                        datas={handleCsvExport}
                    >
                        <Fab color="primary" size="small" title={t("CSV-Export")}>
                            {isExportingCsv &&
                                <Box align="center" style={{ padding: '5px 0 0 0' }}>
                                    <CircularProgress size={200} color='inherit' />
                                </Box>
                            }
                            {!isExportingCsv &&
                                <DownloadIcon />
                            }
                        </Fab>
                    </CsvDownloader>
                </Grid>
            </Grid>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Deck')}</TableCell>
                                <TableCell align="right">{t('Created at')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {carpark && carpark.decks && Array.isArray(carpark.decks) && carpark.decks.map((deck) => (
                                <TableRow
                                    key={deck._id}
                                    sx={{
                                        verticalAlign: 'top',
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link style={{ textDecoration: 'none' }} href={`/deck/${deck._id}`}>{deck.name}</Link>
                                    </TableCell>
                                    <TableCell align="right">{moment(deck.createdAt).format(t("date.format") + " HH:mm")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};
export default CarPark
