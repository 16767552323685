import { Link } from "@mui/material"
import { Breadcrumbs } from "@mui/material"
import HomeIcon from '@mui/icons-material/Home'

const Breadcrumbler = (props) => {
  // console.log("Breadcrumbler", props)

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: '2rem' }}>
        <Link underline="hover" color="inherit" href="/tasks" key="bc-0">
          <HomeIcon />
        </Link>
        <Link underline="hover" color="inherit" href="/tasks" key="bc-1">
          QR-Park
        </Link>
        {props && props.levels && Array.isArray(props.levels) &&
          props.levels.map((level, index) => {
            return (
              <Link underline="hover" color="inherit" href={level.url} key={index + 100}>
                {level.title}
              </Link>
            )
          })
        }
      </Breadcrumbs>
    </>
  );
}

export default Breadcrumbler;