import * as React from 'react'
import { Alert } from '@mui/material'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Joi from 'joi'
import { DateTimePicker } from 'mui-rff'
import 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import localeDe from 'date-fns/locale/de'
import { Radios } from 'mui-rff'
import Condition from '../../utils/formfields/Condition'
import Icon from '@svgr-iconkit/material-community'
import { typeIcon } from '../../utils/icons/Icons'
import { useImperativeHandle } from 'react'
import { useState } from 'react'
import { forwardRef } from 'react'


const TaskFormStepDetails = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const taskTypes = ['park', 'move', 'return', 'extern']

    let lastValuesState = {}
    const initialValues = props.initialValues
    if (props.initialValues && Object.keys(props.initialValues).length === 0) { // if this is a brand new task
        initialValues.dueDateChoice = "now"
        initialValues.type = "park"
    }

    const [errorMessage, setErrorMessage] = useState('')

    useImperativeHandle(ref, () => ({
        getValueList,
        isStepComplete
    }))
    
    const validationSchemaWithDateAndTime = Joi.object({
        name: Joi.string().min(1).max(30),
        dueDateChoice: Joi.string().valid('now', 'date').required(),
        dueDate: Joi.date().greater('now').required(),
        type: Joi.string().valid('park', 'move', 'return', 'extern').required(),
    })

    const validationSchemaWithoutDateAndTime = Joi.object({
        name: Joi.string().min(1).max(30),
        dueDateChoice: Joi.string().valid('now', 'date').required(),
        type: Joi.string().valid('park', 'move', 'return', 'extern').required(),
    })

    const onSubmit = async (values) => {
        console.log("TaskFormStepDetails onSubmit", values)
        return false
    }

    const isStepComplete = () => {
        console.log("TaskFormStepDetails isStepComplete", lastValuesState)
        // @see: https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
        document.getElementById('formInner01').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        if (lastValuesState.error && lastValuesState.error !== undefined) {
            setErrorMessage(t("Please fill all the fields correctly"))
            return false
        }
        setErrorMessage("")
        return true
    }

    const getValueList = () => {
        return lastValuesState
    }

    const validate = async (values) => {
        // console.log("TaskFormStepDetails validate", values)
        lastValuesState = values
        delete lastValuesState.error
        
        let validationResult = undefined
        if (lastValuesState && lastValuesState.dueDateChoice === 'now') {
            delete lastValuesState.dueDate
            validationResult = validationSchemaWithoutDateAndTime.validate(lastValuesState, { abortEarly: false })
        } else {
            validationResult = validationSchemaWithDateAndTime.validate(lastValuesState, { abortEarly: false })
        }

        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
            })
            lastValuesState.error = true
            console.log("errorMessages", errorMessages)
            return errorMessages
        }
        lastValuesState.error = false
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values }) => (
                    <form onSubmit={handleSubmit} id="formInner01" noValidate>
                        <Box sx={{ mt: '1rem' }} >
                            <Radios
                                label={t('Select the task type')}
                                name="type"
                                required={true}
                                data={taskTypes.map((type, index) => {
                                    return {
                                        value: type,
                                        label: <span style={{display:'inline-flex', alignItems:'center'}}><Icon name={typeIcon(type).name} style={{ color: typeIcon(type).color, width: '2rem', marginRight: '0.5rem' }} /> {t(`TaskType.${type}`)}</span>
                                    }
                                })}
                            />
                        </Box>

                        <Box sx={{ mt: '1rem' }} >
                            <Radios
                                label={t('Target date')}
                                name="dueDateChoice"
                                required={true}
                                data={[
                                    { label: t('Now'), value: 'now' },
                                    { label: t('Target date'), value: 'date' }
                                ]}
                            />
                        </Box>

                        <Condition when="dueDateChoice" is="date">
                            <Box sx={{ mt: '1rem' }} >
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeDe}>
                                    <DateTimePicker
                                        label={t('DateAndTime')}
                                        name="dueDate"
                                        required={true}
                                        minDateTime={new Date()}
                                        minutesStep={5}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Condition>

                        {errorMessage &&
                            <Alert severity="warning" sx={{ mt: '1rem' }}>{errorMessage}</Alert>
                        }
                    </form>
                )
                }
            />
        </>
    )
})

export default TaskFormStepDetails