import { createTheme } from "@mui/material"

const theme = createTheme({
    palette: {
        primary: {
            main: '#313639',
        },
        secondary: {
            main: '#d5001c',
        },
        error: {
            main: '#d5001c',
        },
    },
    typography: {
        h1: {
            fontSize: '1.75rem',
            fontWeight: 'bold'
        },
    },
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    width: '50px !important',
                    height: '50px !important',
                    padding: '10px !important'
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: '10px !important'
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingRight: '20px !important'
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#313639',
                    '* .MuiTableCell-root': {
                        color: '#fff'
                    }
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '64px'
                },
            },
        },
    },
})

export default theme