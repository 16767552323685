import DeckMapView from "../decks/DeckMapView"
import { useCallback } from "react"
import { useStoreActions } from "easy-peasy"
import { useState } from "react"
import { useEffect } from "react"
import { Container } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { useRef } from "react"

const CarSpotView = (props) => {
    const componentRef = useRef()

    const [isUpdating, setIsUpdating] = useState(false)

    const [deck, setDeck] = useState(undefined)
    const getDeckAction = useStoreActions(actions => actions.getDeck)

    const fetchData = useCallback(async () => {
        setIsUpdating(true)
        if (props.car && props.car.spot) {
            setDeck(await getDeckAction(props.car.spot.deck))
        }
        setIsUpdating(false)
    }, [getDeckAction, props.car])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating && props.car && props.car.spot && deck &&
                <div ref={componentRef} style={{
                    position: 'relative',
                    width: '100%',
                    height: 300,
                    maxWidth: '100%',
                    maxHeight: 300,
                    border: '1px solid #333',
                    borderRadius: 4,
                    overflow: 'hidden',
                    marginTop: 0
                }}>
                    <DeckMapView wrapperRef={componentRef} deck={deck} car={props.car} selectedSpot={props.car.spot} disableSpotClick={true} />
                </div>
            }
        </>
    )

}

export default CarSpotView