import { Dialog, DialogTitle } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useState } from 'react'

const QrPreview = (props) => {
  // console.log("Breadcrumbler", props)
  const [open, setOpen] = useState(false)


  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {window.__RUNTIME_CONFIG__.QR_PREVIEW !== "true" &&
        <>
          {props.children}
        </>
      }
      {window.__RUNTIME_CONFIG__.QR_PREVIEW === "true" &&
        <>
          <div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => setOpen(true)}>
            {props.children}
          </div>
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{props.item.qr}</DialogTitle>
            <div style={{ padding: 20 }}>
              <QRCodeSVG value={props.item.qr} width="200" height="200" />
            </div>
          </Dialog>
        </>
      }
    </>
  )
}

export default QrPreview