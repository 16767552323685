import { CircularProgress } from '@mui/material'
import { ListItem } from '@mui/material'
import { Avatar } from '@mui/material'
import { ListItemText } from '@mui/material'
import { ListItemAvatar } from '@mui/material'
import { List } from '@mui/material'
import { Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useStoreState, useStoreActions } from 'easy-peasy'
import React, { useEffect, useState } from 'react'
import ImageIcon from '@mui/icons-material/Image'
import WorkIcon from '@mui/icons-material/Work'
import TaskIcon from '@mui/icons-material/PlaylistAddCheck'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import ShieldIcon from '@mui/icons-material/Shield'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'

const Tenant = ({ navigation }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isUpdating, setIsUpdating] = useState(false)

  const context = useStoreState(state => state.context)
  const tenant = useStoreState(state => state.tenant)
  const getTenant = useStoreActions(actions => actions.getTenant)
  // const getCurrentTenantId = useStoreActions(actions => actions.getCurrentTenantId)
  const [currentTenantId, setCurrentTenantId] = React.useState(undefined)

  useEffect(() => {
    const fetchData = async () => {
      setIsUpdating(true)
      setCurrentTenantId(localStorage.getItem('currentTenantId'))
      await getTenant() //await getTenant({ tenantId: tenantId });  // we pull the tenantId from localStorage instead. See Store.js
      setIsUpdating(false)
    }
    fetchData();
  }, [setCurrentTenantId, getTenant])

  return (
    <>
      <Breadcrumbler levels={[
        // { title: localStorage.getItem('currentTenantName'), url: '/tenant' }
      ]} />

      <Typography variant="h1" component="div" gutterBottom>
        {tenant && tenant.name}
      </Typography>

      {isUpdating &&
        <Container align="center">
          <CircularProgress />
        </Container>
      }

      {!isUpdating &&
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

          <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/cars') }}>
            <ListItemAvatar>
              <Avatar>
                <BeachAccessIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t("Cars")}
            // secondary={t("See a list of cars")}
            />
          </ListItem>

          {currentTenantId && context && context.roles && Array.isArray(context.roles) && !context.roles.includes('ServiceOperator') &&
            <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/keylockers') }}>
              <ListItemAvatar>
                <Avatar>
                  <ShieldIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t("Keylocker")}
              />
            </ListItem>
          }
          
          {currentTenantId && context && context.roles && Array.isArray(context.roles) && !context.roles.includes('ServiceOperator') &&
            <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/employees') }}>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={t("Employees")}
              //  secondary={t("Manage your human resources")}
              />
            </ListItem>
          }

          <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/carparks') }}>
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t("Carparks")}
            //  secondary={t("View your locations")}
            />
          </ListItem>


          <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/tasks') }}>
            <ListItemAvatar>
              <Avatar>
                <TaskIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={t("Tasks")}
            // secondary={t("View the tasks")}
            />
          </ListItem>


        </List>
      }
    </>
  )

}

export default Tenant