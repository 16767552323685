import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { Link } from '@mui/material'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import moment from 'moment'

const CarParks = () => {
    const { t } = useTranslation()

    const [isUpdating, setIsUpdating] = useState(false)

    const carparks = useStoreState(state => state.carparks)
    const getCarparksAction = useStoreActions(actions => actions.getCarparks)

    useEffect(() => {
        const fetchData = async () => {
            setIsUpdating(true)
            await getCarparksAction()
            setIsUpdating(false)
        }
        fetchData()
    }, [getCarparksAction])

    return (
        <>
            <Breadcrumbler levels={[{ title: t("Car parks"), url: '/carparks' }]} />

            <Typography variant="h1" component="div" gutterBottom>
                {t("Car parks")}
            </Typography>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating &&
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Car park')}</TableCell>
                                <TableCell align="right">{t('Created at')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {carparks && Array.isArray(carparks) && carparks.map((carpark) => (
                                <TableRow
                                    key={carpark._id}
                                    sx={{
                                        verticalAlign: 'top',
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Link style={{ textDecoration: 'none' }} href={`/carpark/${carpark._id}`}>{carpark.name}</Link>
                                    </TableCell>
                                    <TableCell align="right">{moment(carpark.createdAt).format(t("date.format") + " HH:mm")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    );
};
export default CarParks
