import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { TextField } from 'mui-rff'
import { QrReader } from 'react-qr-reader'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { InputAdornment } from '@mui/material'
import { IconButton } from '@mui/material'
import { Box } from '@mui/material'
import { useState } from 'react'
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos'

const QrScannerTextFieldMuiRff = ({ input, meta, ...rest }) => {
    const { t } = useTranslation()

    const [showQrScanner, setShowQrScanner] = useState(false)
    const [cameraFacingMode, setCameraFacingMode] = useState('environment')

    return (
        <>
            <TextField
                {...input}
                {...rest}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { setShowQrScanner(!showQrScanner) }}
                                edge="end"
                            >
                                <QrCodeScannerIcon />
                            </IconButton>
                        </InputAdornment>
                }}
            />

            {showQrScanner &&
                <Dialog
                    open={true}
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle>{t('Scan a QR-Code')}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ backgroundColor: '#ccc', border: '1px solid #333', padding: 0, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 0, right: 0, display: 'inline-block', padding: 2, zIndex: 100 }}>
                                <IconButton onClick={() => { cameraFacingMode === 'environment' ? setCameraFacingMode('user') : setCameraFacingMode('environment') }}>
                                    <FlipCameraIosIcon />
                                </IconButton>
                            </div>
                            <QrReader
                                key={cameraFacingMode}
                                constraints={{
                                    facingMode: cameraFacingMode
                                }}
                                onResult={(result, error) => {
                                    if (result && !error) {
                                        console.log('QrReader', result, error)
                                        input.onChange(result.text)
                                        setShowQrScanner(false)
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ mb: '1rem' }} >
                        <Button onClick={() => { setShowQrScanner(false) }} variant="contained">{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default QrScannerTextFieldMuiRff