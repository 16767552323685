import React from "react"
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch"
import Icon from '@svgr-iconkit/material-community'
import { spotTypeIcon } from '../../utils/icons/Icons'
import { Container } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { useRef } from "react"
import { useState } from "react"


/* @see: https://github.com/prc5/react-zoom-pan-pinch*/
/* @see: https://prc5.github.io/react-zoom-pan-pinch */

const DeckEditorMapView = (props) => {

    const markerSize = 50

    const [domImgObj, setDomImgObj] = useState(undefined)
    const [isImageLoading, setIsImageLoading] = useState(true)
    const [selectedSpot, setSelectedSpot] = useState(props.selectedSpot)
    const [mousePosSnapped, setMousePosSnapped] = useState({ x: 0, y: 0 })


    const transformWrapperRef = useRef()

    const onSpotSelected = (spot) => {
        setSelectedSpot(spot)
        if (props.spotSelectedCallback) {
            props.spotSelectedCallback(spot)
        }
    }
    const onKeylockerSelected = (spot) => {
        if (props.keylockerSelectedCallback) {
            props.keylockerSelectedCallback(spot)
        }
    }
    const onMapMouseMove = (e) => {
        const stepSize = 5
        setMousePosSnapped({
            x: Math.round(e.nativeEvent.offsetX / stepSize) * stepSize,
            y: Math.round(e.nativeEvent.offsetY / stepSize) * stepSize
        })
    }
    const onMapClick = (e) => {
        if (props.mapClickCallback) {
            props.mapClickCallback({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
        }
    }
    const onMapDoubleClick = (e) => {
        if (props.mapDoubleClickCallback) {
            props.mapDoubleClickCallback({ x: mousePosSnapped.x, y: mousePosSnapped.y })
        }
    }

    const onImageLoad = (e) => {
        setDomImgObj(e.target)
        setIsImageLoading(false)
        if (selectedSpot) {
            zoomToSpot(selectedSpot, 1.0)
        } else if (e.target) {
            // fitDomObjectToContainer(e.target)
        }
    }

    const fitDomObjectToContainer = (domImg) => {
        const { setTransform } = transformWrapperRef.current
        const scale = props.containerHeight / domImg.naturalHeight
        setTransform(
            -((domImg.naturalWidth / 2 * scale) - props.containerWidth / 2),
            -((domImg.naturalHeight / 2 * scale) - props.containerHeight / 2),
            scale,
            250,
            'easeOutQuad')
    }

    const zoomToSpot = (spot, scale = 0.75) => {
        const { setTransform } = transformWrapperRef.current
        setTransform(
            -((spot.x * scale) - props.containerWidth / 2),
            -((spot.y * scale) - props.containerHeight / 2),
            scale,
            250,
            'easeOutQuad')
    }

    const onImageLoadError = (e) => {
        console.log('onImageLoadError', e)
    }

    return (
        <TransformWrapper
            initialScale={1.00}
            disabled={false}
            minScale={0.125}
            maxScale={1.00}
            limitToBounds={false}
            centerZoomedOut={false}
            centerOnInit={false}
            doubleClick={{ disabled: true }}
            wheel={{ step: 0.25 }}
            style={{ position: 'absolute' }}
            ref={transformWrapperRef}
        >
            {({ zoomIn, zoomOut, resetTransform, zoomToElement, ...rest }) => (
                <>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1000
                    }}
                    >
                        <button onClick={() => zoomIn()} style={{ marginLeft: 2, marginRight: 2, width: 24, height: 24 }}>+</button>
                        <button onClick={() => zoomOut()} style={{ marginRight: 2, width: 24, height: 24 }}>-</button>
                        <button onClick={() => fitDomObjectToContainer(domImgObj)} style={{ width: 24, height: 24 }}>x</button>
                    </div>

                    <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1000,
                        textAlign: 'right',
                        backgroundColor: 'rgba(0.49, 0.54, 0.57, 0.35',
                        borderBottom: '1px solid #313639',
                        borderLeft: '1px solid #313639',
                        color: '#fff',
                        padding: '0.25rem 0.5rem',
                        cursor: 'default',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        userSelect: 'none'
                    }}>
                        {mousePosSnapped.x}, {mousePosSnapped.y}
                    </div>

                    <div style={{
                        position: 'absolute',
                        left: mousePosSnapped.x * (transformWrapperRef.current ? transformWrapperRef.current.state.scale : 1.0) + (transformWrapperRef.current ? transformWrapperRef.current.state.positionX : 0) - (markerSize * 0.5 * (transformWrapperRef.current ? transformWrapperRef.current.state.scale : 1.0)),
                        top: mousePosSnapped.y * (transformWrapperRef.current ? transformWrapperRef.current.state.scale : 1.0) + (transformWrapperRef.current ? transformWrapperRef.current.state.positionY : 0) - (markerSize * 0.5 * (transformWrapperRef.current ? transformWrapperRef.current.state.scale : 1.0)),
                        zIndex: 900,
                        width: markerSize * (transformWrapperRef.current ? transformWrapperRef.current.state.scale : 1.0),
                        height: markerSize * (transformWrapperRef.current ? transformWrapperRef.current.state.scale : 1.0),
                        pointerEvents: 'none',
                        border: '1px solid #ff00ff',
                    }}
                    ></div>

                    <TransformComponent
                        wrapperStyle={{
                            width: props.containerWidth ? props.containerWidth : 100,
                            height: props.containerHeight ? props.containerHeight : 100,
                        }}
                    >
                        <div onClick={onMapClick} onDoubleClick={onMapDoubleClick} onMouseMove={onMapMouseMove}>
                            {props.deck && props.deck.image &&
                                <img alt='Parkdeck' src={`${window.__RUNTIME_CONFIG__.BASE_URL}${props.deck.image}`} onLoad={onImageLoad} onError={onImageLoadError}></img>
                            }
                        </div>
                        {isImageLoading &&
                            <Container align="center">
                                <CircularProgress />
                            </Container>
                        }
                        {!isImageLoading && props.deck && props.deck.spots && props.deck.spots.map((spot) => {
                            const icon = spot.type === 'parkingspot' && spot.car ? spotTypeIcon('parkingspot-occupied') : spotTypeIcon(spot.type)
                            return (
                                <div id={`spot-wrapper-${spot._id}`} title={spot.name} key={`spot-wrapper-${spot._id}`} className={"spotWrapper"}
                                    style={{
                                        position: 'absolute',
                                        marginLeft: spot.x - markerSize / 2,
                                        marginTop: spot.y - markerSize / 2,
                                    }}>
                                    <Icon name={icon.name} style={{ cursor: 'pointer', color: icon.color, width: markerSize }} onClick={(e) => {
                                        if (props.disableSpotClick) {
                                            return false
                                        }
                                        zoomToSpot(spot)
                                        onSpotSelected(spot)
                                    }} />
                                    {/* <div style={{textAlign:'center'}}>{spot.name && spot.name.substring(spot.name.length - 2)}</div> */}
                                </div>
                            )
                        })}
                        {!isImageLoading && props.deck && props.deck.keylockers && props.deck.keylockers.map((keylocker) => {
                            const icon = spotTypeIcon('keylocker')
                            return (
                                <div id={`keylocker-wrapper-${keylocker._id}`} title={keylocker.name} key={`keylocker-wrapper-${keylocker._id}`} className={"keylockerWrapper"}
                                    style={{
                                        position: 'absolute',
                                        marginLeft: keylocker.x - markerSize / 2,
                                        marginTop: keylocker.y - markerSize / 2,
                                    }}>
                                    <Icon name={icon.name} style={{ cursor: 'pointer', color: icon.color, width: markerSize }} onClick={(e) => {
                                        if (props.disableSpotClick) {
                                            return false
                                        }
                                        zoomToSpot(keylocker)
                                        onKeylockerSelected(keylocker)
                                    }} />
                                </div>
                            )
                        })}
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    )
}
export default DeckEditorMapView
