import { Typography } from '@mui/material'
import * as React from 'react'
// import { useStoreActions } from 'easy-peasy'

const Home = () => {

    // const createSpot = useStoreActions(actions => actions.createSpot)
    // const persistMockSpots = async () => {
    //     let result = undefined

    //     result = await createSpot({
    //         "carpark": "62b980d26dffee54288fed8b",
    //         //"car": "62d101aa5b97b6dc140b9104",
    //         "deck": "62bc22ed674ca575f5961e65",
    //         "name": "31",
    //         "type": "parkingspot",
    //         "x": "810",
    //         "y": "340",
    //         "qr": "PPP1234-31",
    //         "tenant": "62b9814784d31169357bd7c6"
    //     })
    //     console.log("result", result)
        
    //     result = await createSpot({
    //         "carpark": "62b980d26dffee54288fed8b",
    //         //"car": "62d101aa5b97b6dc140b9104",
    //         "deck": "62bc22ed674ca575f5961e65",
    //         "name": "37",
    //         "type": "parkingspot",
    //         "x": "605",
    //         "y": "2310",
    //         "qr": "PPP1234-37",
    //         "tenant": "62b9814784d31169357bd7c6"
    //     })
    //     console.log("result", result)

    //     result = await createSpot({
    //         "carpark": "62b980d26dffee54288fed8b",
    //         //"car": "62d101aa5b97b6dc140b9104",
    //         "deck": "62bc22ed674ca575f5961e65",
    //         "name": "82",
    //         "type": "parkingspot",
    //         "x": "3700",
    //         "y": "2480",
    //         "qr": "PPP1234-82",
    //         "tenant": "62b9814784d31169357bd7c6"
    //     })
    //     console.log("result", result)
    // }
    // persistMockSpots()

    return (
        <>
            <Typography variant="h1" component="div" gutterBottom>
                Home
            </Typography>
        </>
    )
}
export default Home
