import { useCallback } from "react"
import { useEffect, useState } from "react"

/**
 * @see https://stackoverflow.com/a/60978633
 */
export const useContainerDimensions = (myRef) => {
  const getDimensions = useCallback(() => {
    return {
      width: myRef.current ? myRef.current.offsetWidth : 1,
      height: myRef.current ? myRef.current.offsetHeight : 1
    }
  }, [myRef])

  const [dimensions, setDimensions] = useState({ width: 1, height: 1 })

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    // cleanup
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef, getDimensions])

  return dimensions
}