import { Alert } from '@mui/material'
import { Dialog } from '@mui/material'
import { Button } from '@mui/material'
import { DialogActions } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { DialogContentText } from '@mui/material'
import { DialogContent } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import Joi from 'joi'
import { Radios } from 'mui-rff'
import { TextField } from 'mui-rff'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Field } from 'react-final-form'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import QrScannerTextFieldMuiRff from '../../utils/formfields/QrScannerTextFieldMuiRff'
import { useContainerDimensions } from '../../utils/useContainerDimensions'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import DeckEditorMapView from './DeckEditorMapView'
import createDecorator from 'final-form-calculate'

const spotTypeIdsByType = {
    'parkingspot': 1,
    'keylocker': 4,
    'servicedesk': 5,
    'repair': 6,
    'pickup': 7
}

const generateSpotName = (nr) => {
    // @see: https://rsdd.atlassian.net/wiki/spaces/RSPA/pages/11960321/Information#Naming-of-slots
    if (!nr) {
        return ''
    }
    return 'PSUG2' + nr.padStart(3, '0')
}
const generateSpotQr = (type, nr) => {
    // @see: https://rsdd.atlassian.net/wiki/spaces/RSPA/pages/11960321/Information#QR-Code-Format
    if (!nr) {
        return ''
    }
    const typeChar = spotTypeIdsByType[type]
    return 'CM01' + typeChar + 'U2' + nr.padStart(3, '0')
}

const formNameAndQrGenerator = createDecorator(
    {
        field: 'nr',
        updates: {
            name: function (nr, allValues) {
                return generateSpotName(nr)
            },
            qr: function (nr, allValues) {
                return generateSpotQr(allValues.type, nr)
            }
        }
    },
    {
        field: 'type',
        updates: {
            name: function (type, allValues) {
                return generateSpotName(allValues.nr)
            },
            qr: function (type, allValues) {
                return generateSpotQr(type, allValues.nr)
            }
        }
    })


const DeckEditor = () => {
    const { t } = useTranslation()

    const componentRef = useRef()
    const { width: containerWidth, height: containerHeight } = useContainerDimensions(componentRef)

    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [showCreateSpotForm, setShowCreateSpotForm] = useState(false)
    const [showDeleteSpotDialog, setShowDeleteSpotDialog] = useState(false)
    const [showDeleteKeylockerDialog, setShowDeleteKeylockerDialog] = useState(false)
    const [selectedSpot, setSelectedSpot] = useState(undefined)
    const [selectedKeylocker, setSelectedKeylocker] = useState(undefined)
    const [errorMessage, setErrorMessage] = useState('')
    const [lastClickPos, setLastClickPos] = useState(undefined)
    const [lastSpotNr, setLastSpotNr] = useState(0)

    const carpark = useStoreState(state => state.carpark)
    const getCarpark = useStoreActions(actions => actions.getCarpark)
    const createSpot = useStoreActions(actions => actions.createSpot)
    const createKeylocker = useStoreActions(actions => actions.createKeylocker)
    const deleteSpotAction = useStoreActions(actions => actions.deleteSpot)
    const deleteKeylockerAction = useStoreActions(actions => actions.deleteKeylocker)
    const resetSpot = useStoreActions(actions => actions.resetSpot)
    const deck = useStoreState(state => state.deck)
    const getDeck = useStoreActions(actions => actions.getDeck)

    const { deckId } = useParams()

    const initialValues = {
        'type': 'parkingspot'
    }

    const spotTypes = [
        { value: 'keylocker', label: t('SpotType.keylocker') },
        { value: 'parkingspot', label: t('SpotType.parkingspot') },
        { value: 'repair', label: t('SpotType.repair') },
        { value: 'servicedesk', label: t('SpotType.servicedesk') },
        { value: 'pickup', label: t('SpotType.pickup') },
    ]


    const validationSchema = Joi.object({
        nr: Joi.number().integer().max(99999).required(),
        name: Joi.string().required(),
        type: Joi.string().valid('servicedesk', 'parkingspot', 'repair', 'keylocker', 'pickup').required(),
        qr: Joi.string().min(10).max(10).required(),
    })

    const breadcrumbLevels = [{ title: t("Car parks"), url: '/carparks' }]
    if (carpark && deck) {
        breadcrumbLevels.push({ title: carpark.name, url: '/carpark/' + carpark._id })
        breadcrumbLevels.push({ title: deck.name, url: '/deck/' + deck._id })
    }

    const fetchData = useCallback(async () => {
        setIsUpdating(true)
        const deck = await getDeck(deckId)
        if (deck && deck.carpark) {
            await getCarpark(deck.carpark._id)
        }
        setIsUpdating(false)
    }, [getCarpark, getDeck, deckId])


    useEffect(() => {
        fetchData()
    }, [fetchData])

    const mapClickCallback = (pos) => {
        if (!pos) {
            return
        }
        setLastClickPos(pos)
        setSelectedSpot(undefined)
    }
    const mapDoubleClickCallback = (pos) => {
        console.log("mapDoubleClickCallback", pos)
        if (!pos) {
            return
        }
        setLastClickPos(pos)
        setShowCreateSpotForm(true)
    }
    const spotSelectedCallback = (spot) => {
        console.log("spotSelectedCallback", spot)
        setSelectedSpot(spot)
        setShowDeleteSpotDialog(true)
    }
    const keylockerSelectedCallback = (keylocker) => {
        console.log("keylockerSelectedCallback", keylocker)
        setSelectedKeylocker(keylocker)
        setShowDeleteKeylockerDialog(true)
    }

    const cancel = async () => {
        setShowCreateSpotForm(false)
    }

    const validate = async (values) => {
        const validationResult = validationSchema.validate(values, { abortEarly: false })
        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            setIsSubmitting(false)
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
            })
            return errorMessages
        }
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    const onSubmit = async (values) => {
        console.log('onSubmit', values, lastClickPos)

        const payload = {
            carpark: carpark._id,
            deck: deck._id,
            x: lastClickPos.x,
            y: lastClickPos.y,
            name: values.name,
            type: values.type,
            qr: values.qr,
        }

        setErrorMessage(undefined)
        setIsSubmitting(true)
        await resetSpot()

        let result = undefined
        if (values.type === 'keylocker') {
            delete payload.type
            result = await createKeylocker(payload)
            if (result._id) {
                setSelectedKeylocker(result)
                setSelectedSpot(undefined)
            }
        } else {
            result = await createSpot(payload)
            if (result._id) {
                setSelectedKeylocker(undefined)
                setSelectedSpot(result)
            }
        }

        if (result.error) {
            console.log('result.error', result.error)
            setErrorMessage(result.error.message)
            setIsSubmitting(false)
            return false
        }

        setLastSpotNr(values.nr)
        setShowCreateSpotForm(false)
        setLastClickPos(undefined)
        setIsSubmitting(false)
        fetchData()
    }

    const deleteKeylocker = async () => {
        console.log("deleteKeylocker", selectedKeylocker)
        if (!((selectedKeylocker && selectedKeylocker._id))) {
            throw new Error("selectedKeylocker is invalid!")
        }
        setIsDeleting(true)
        await deleteKeylockerAction(selectedKeylocker._id)
        setShowDeleteKeylockerDialog(false)
        setSelectedKeylocker(undefined)
        setIsDeleting(false)
        fetchData()
    }

    const deleteSpot = async () => {
        console.log("deleteSpot", selectedSpot)
        if (!((selectedSpot && selectedSpot._id))) {
            throw new Error("selectedSpot is invalid!")
        }
        setIsDeleting(true)
        await deleteSpotAction(selectedSpot._id)
        setShowDeleteSpotDialog(false)
        setSelectedSpot(undefined)
        setIsDeleting(false)
        fetchData()
    }

    return (
        <>
            <Breadcrumbler levels={breadcrumbLevels} />
            <Typography variant="h1" component="div" gutterBottom>
                {t("Deck Editor")} ({lastSpotNr})
            </Typography>

            {!isUpdating &&
                <div
                    ref={componentRef}
                    style={{
                        position: 'relative',
                        minHeight: 500,
                        border: '1px solid #666',
                        backgroundColor: '#666',
                        borderRadius: 3,
                        overflow: 'hidden',
                    }}
                >
                    <DeckEditorMapView selectedSpot={selectedSpot ? selectedSpot : selectedKeylocker} containerWidth={containerWidth} containerHeight={containerHeight} deck={deck} keylockerSelectedCallback={keylockerSelectedCallback} spotSelectedCallback={spotSelectedCallback} mapClickCallback={mapClickCallback} mapDoubleClickCallback={mapDoubleClickCallback} />
                </div>
            }

            {!isUpdating && selectedSpot &&
                <Alert severity="success" sx={{ mt: '1rem' }}>
                    Name: {selectedSpot.name}<br />
                    QR-Code: {selectedSpot.qr}<br />
                    Typ: {selectedSpot.type}
                </Alert>
            }

            {!isUpdating && errorMessage &&
                <Alert severity="warning" sx={{ mt: '1rem' }}>{errorMessage}</Alert>
            }

            <Dialog open={!isUpdating && showCreateSpotForm}>
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    decorators={[formNameAndQrGenerator]}
                    validate={validate}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <DialogTitle>{t('New Spot')}</DialogTitle>
                            <DialogContent>
                                <Radios
                                    label={t('Select the spot type')}
                                    name="type"
                                    required={true}
                                    data={spotTypes}
                                />
                                <TextField sx={{ mb: '1.5rem' }} label={t('Nr.')} name="nr" required={true} autoFocus={true} autoComplete={'off'}/>
                                <TextField sx={{ mb: '1.5rem' }} label={t('Name')} name="name" required={true} />
                                <Field name="qr" component={QrScannerTextFieldMuiRff} sx={{ mt: '0', mb: '1.5rem' }} label={t('QR-Code')} required={true} />
                                {errorMessage &&
                                    <Alert severity="warning">{errorMessage}</Alert>
                                }
                            </DialogContent>
                            <DialogActions sx={{ mb: '1rem' }} >
                                <Button onClick={() => { cancel() }}>{t('Cancel')}</Button>
                                <Button type="submit" disabled={isSubmitting}>{t('form.Submit')}</Button>
                            </DialogActions>
                        </form>
                    )}
                />
            </Dialog>

            <Dialog
                open={showDeleteSpotDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Delete Spot')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Do you really want to delete this Spot?')}<br />
                        <br />
                        {selectedSpot && `${t('Name')}:  ${selectedSpot.name}`}<br />
                        {selectedSpot && `${t('Type')}:  ${selectedSpot.type}`}<br />
                        {selectedSpot && `${t('QR-Code')}: ${selectedSpot.qr}`}<br />
                        {selectedSpot && `${t('Pos')}: ${selectedSpot.x}, ${selectedSpot.y}`}<br />
                        <br />
                    </DialogContentText>
                    {isDeleting &&
                        <Container align="center">
                            <CircularProgress />
                        </Container>
                    }
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowDeleteSpotDialog(false) }} autoFocus disabled={isDeleting}>{t('Cancel')}</Button>
                    <Button onClick={() => { deleteSpot() }} variant="contained" color="error" disabled={isDeleting}>{t('Delete Spot')}</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDeleteKeylockerDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Delete Keylocker')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Do you really want to delete this keylocker?')}<br />
                        <br />
                        {selectedKeylocker && `${t('Name')}:  ${selectedKeylocker.name}`}<br />
                        {selectedKeylocker && `${t('QR-Code')}: ${selectedKeylocker.qr}`}<br />
                        <br />
                    </DialogContentText>
                    {isDeleting &&
                        <Container align="center">
                            <CircularProgress />
                        </Container>
                    }
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowDeleteKeylockerDialog(false) }} autoFocus disabled={isDeleting}>{t('Cancel')}</Button>
                    <Button onClick={() => { deleteKeylocker() }} variant="contained" color="error" disabled={isDeleting}>{t('Delete Keylocker')}</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default DeckEditor

