import * as React from 'react'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-final-form'
import Joi from 'joi'
import { Autocomplete } from 'mui-rff'
import CarForm from '../cars/CarForm'
import CarQrCodeForm from '../cars/CarQrCodeForm'
import AddIcon from '@mui/icons-material/Add'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import { QrReader } from 'react-qr-reader'
import { Box } from '@mui/material'
import { Fab } from '@mui/material'
import { useStoreActions } from 'easy-peasy'
import { useStoreState } from 'easy-peasy'
import { Container } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { DialogContent } from '@mui/material'
import { DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useImperativeHandle } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { forwardRef } from 'react'
import { IconButton } from '@mui/material'
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos'

const TaskFormStepCar = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const formRef = useRef(null)

    const initialValues = props.initialValues
    let lastValuesState = {}

    const cars = useStoreState(state => state.cars)
    const getCarsAction = useStoreActions(actions => actions.getCars)

    const [isUpdating, setIsUpdating] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [qrCodeDialogErrorMessage, setQrCodeDialogErrorMessage] = useState('')
    const [showAddNewCarForm, setShowAddNewCarForm] = useState(false)
    const [showAddCarQrCodeForm, setShowAddCarQrCodeForm] = useState(false)
    const [showReadQrCodeDialog, setShowReadQrCodeDialog] = useState(false)
    const [selectedCarData, setSelectedCarData] = useState(props.initialValues && props.initialValues.car ? props.initialValues.car : null)
    const [cameraFacingMode, setCameraFacingMode] = useState('environment')

    useImperativeHandle(ref, () => ({
        getValueList,
        isStepComplete
    }))

    const fetchData = useCallback(async () => {
        setIsUpdating(true)
        await getCarsAction()
        setIsUpdating(false)
    }, [getCarsAction])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    const validationSchema = Joi.object({
        car: Joi.object().keys({
            _id: Joi.string(),
            qr: Joi.string().min(10).max(10).required(),
         }).unknown(true),
    })

    const onSubmit = async (values) => {
        console.log("TaskFormStepCar onSubmit", values)
        return false
    }

    const isStepComplete = () => {
        console.log("TaskFormStepCar isStepComplete", lastValuesState)
        // @see: https://final-form.org/docs/react-final-form/faq#how-can-i-trigger-a-submit-from-outside-my-form
        document.getElementById('formInner01').dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        validate(lastValuesState) // this call should not be neccessary, but if i leave it out, the validation is not executed until the user interacts with the autocomplete-field

        if(lastValuesState && lastValuesState.car && !lastValuesState.car.qr) {
            console.log("TaskFormStepCar isStepComplete CAR QR-CODE INVALID", lastValuesState)
            setShowAddCarQrCodeForm(true)
        }

        if (lastValuesState.error && lastValuesState.error !== undefined) {
            setErrorMessage(t("Please fill all the fields correctly"))
            return false
        }
        setErrorMessage("")
        return true
    }

    const getValueList = () => {
        lastValuesState.car = selectedCarData
        return lastValuesState
    }

    const validate = async (values) => {
        // console.log("TaskFormStepCar validate", values)
        lastValuesState = values
        delete lastValuesState.error

        let validationResult = validationSchema.validate(lastValuesState, { abortEarly: false })

        // console.log("validationResult", validationResult, values)

        if (validationResult.error && Array.isArray(validationResult.error.details)) {
            const errorMessages = {}
            validationResult.error.details.forEach((errorDetail) => {
                console.log('errorDetail', errorDetail)
                errorMessages[errorDetail.context.key] = t(`formError.${errorDetail.message}`)
            })
            lastValuesState.error = true
            return errorMessages
        }
        lastValuesState.error = false
        return {} // Return {} or undefined when the values are valid, or an Object of validation errors when the values are invalid.
    }

    const findCarByQr = (qr) => {
        console.log("findCarByQr", cars, qr)
        if (!qr) {
            return undefined
        }
        return cars && cars.data ? cars.data.find(car => car.qr === qr) : undefined
    }

    const getCarOptionLabel = (option) => {
        const parts = []
        if (option.name) { parts.push(option.name) }
        if (option.type) { parts.push(option.type) }
        if (option.numberPlate) { parts.push(option.numberPlate) }
        if (option.customerName) { parts.push(option.customerName) }
        if (option.vin) { parts.push(option.vin) }
        if (option.qr) { parts.push(option.qr) }
        return parts.join(', ')
    }

    const sortCarsCompareFunction = (a, b) => {
        if (!(a.name && b.name)) {
            return 0
        }
        return a.name.localeCompare(b.name)
    }

    return (
        <>
            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating && cars && cars.data && Array.isArray(cars.data) &&
                <Form
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validate={validate}
                    render={({ handleSubmit, form }) => {
                        formRef.current = form
                        return (
                            <form onSubmit={handleSubmit} id="formInner01" ref={formRef} noValidate >
                                <Autocomplete
                                    label={t("Choose a car")}
                                    noOptionsText={t("No options")}
                                    name="car"
                                    required={true}
                                    getOptionValue={option => option}
                                    options={cars.data.sort(sortCarsCompareFunction)}
                                    getOptionLabel={option => getCarOptionLabel(option)}
                                    disableCloseOnSelect={false}
                                    value={selectedCarData}
                                    isOptionEqualToValue={(option, value) => { return option._id === value._id }}
                                    onChange={(event, value) => { setSelectedCarData(value); setErrorMessage(undefined) }}
                                /** 
                                 * [2022-07-14]
                                 * The mui-rff Autocomplete has a bug leading to an "MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled."-Error
                                 * @see: https://github.com/lookfirst/mui-rff/issues/308
                                 */
                                />

                                {errorMessage &&
                                    <Alert severity="warning" sx={{ mt: '1rem' }}>{errorMessage}</Alert>
                                }
                            </form>
                        )
                    }}
                />
            }

            {!isUpdating &&
                <Box sx={{ width: 'auto', textAlign: 'right', mt: '1.5rem' }}>
                    <Fab color="primary" aria-label="add" size="small" sx={{ mr: '1rem' }} onClick={() => { setShowAddNewCarForm(true) }}>
                        <AddIcon />
                    </Fab>
                    <Fab color="primary" aria-label="add" size="small" onClick={() => { setShowReadQrCodeDialog(true) }} >
                        <QrCodeScannerIcon />
                    </Fab>
                </Box>
            }

            {!isUpdating && showReadQrCodeDialog &&
                <Dialog
                    open={true}
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogTitle>{t('Scan a QR-Code')}</DialogTitle>
                    <DialogContent>
                        <Box sx={{ backgroundColor: '#ccc', border: '1px solid #333', padding: 0, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: 0, right: 0, display: 'inline-block', padding: 2, zIndex: 100 }}>
                                <IconButton onClick={() => { cameraFacingMode === 'environment' ? setCameraFacingMode('user') : setCameraFacingMode('environment') }}>
                                    <FlipCameraIosIcon />
                                </IconButton>
                            </div>
                            <QrReader
                                key={cameraFacingMode}
                                constraints={{
                                    facingMode: cameraFacingMode
                                }}
                                onResult={(result, error) => {
                                    if (result && !error) {
                                        const car = findCarByQr(result.text)
                                        if (!car) {
                                            setQrCodeDialogErrorMessage(t("Car with QR '%QRCODE%' not found.").replace('%QRCODE%', result.text))
                                        } else {
                                            setSelectedCarData(car)
                                            formRef.current.change('car', car)
                                            setShowReadQrCodeDialog(false)
                                        }
                                    }
                                }}
                                style={{ width: '100%' }}
                            />
                        </Box>
                        {qrCodeDialogErrorMessage &&
                            <Alert severity="warning" sx={{ mt: '1rem' }}>{qrCodeDialogErrorMessage}</Alert>
                        }
                    </DialogContent>
                    <DialogActions sx={{ mb: '1rem' }} >
                        <Button onClick={() => { setShowReadQrCodeDialog(false); setQrCodeDialogErrorMessage('') }}>{t('Cancel')}</Button>
                    </DialogActions>
                </Dialog>
            }

            {!isUpdating && showAddNewCarForm &&
                <CarForm closeDialog={(newCar) => {
                    if (newCar) {
                        setSelectedCarData(newCar)
                        formRef.current.change('car', newCar)
                        setErrorMessage('')
                        fetchData()
                    }
                    setShowAddNewCarForm(false)
                }} />
            }

            {!isUpdating && showAddCarQrCodeForm &&
                <CarQrCodeForm carData={selectedCarData} closeDialog={(updatedCar) => {
                    if (updatedCar) {
                        setSelectedCarData(updatedCar)
                        formRef.current.change('car', updatedCar)
                        setErrorMessage('')
                        fetchData()
                    }
                    setShowAddCarQrCodeForm(false)
                }} />
            }
        </>
    )
})

export default TaskFormStepCar