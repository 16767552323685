import AddIcon from '@mui/icons-material/Add'
import { Table } from '@mui/material'
import { TableHead } from '@mui/material'
import { TableContainer } from '@mui/material'
import { TableRow } from '@mui/material'
import { TableBody } from '@mui/material'
import { Container } from '@mui/material'
import { Paper } from '@mui/material'
import { Fab } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { TableCell } from '@mui/material'
import { Typography } from '@mui/material'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmployeeForm from './EmployeeForm'
import Breadcrumbler from '../breadcrumbs/Breadcrumbler'
import { Dialog } from '@mui/material'
import { DialogTitle } from '@mui/material'
import { DialogContent } from '@mui/material'
import { DialogContentText } from '@mui/material'
import { DialogActions } from '@mui/material'
import { Button } from '@mui/material'
import moment from 'moment'
import { Grid } from '@mui/material'
import { TextField } from '@mui/material'
import { Box } from '@mui/material'
import { Pagination } from '@mui/material'

const Employees = () => {
    const { t } = useTranslation()
    const itemsPerPage = 10

    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showEmployeeForm, setShowEmployeeForm] = useState(false)
    const [showDeleteEmployeeDialog, setShowDeleteEmployeeDialog] = useState(false)
    const [targetEmployee, setTargetEmployee] = useState(undefined)
    const [searchTerm, setSearchTerm] = useState('')
    const [pageNr, setPageNr] = useState(1)

    const employees = useStoreState(state => state.employees)
    const getEmployeesAction = useStoreActions(actions => actions.getEmployees)
    const deleteEmployeeAction = useStoreActions(actions => actions.deleteEmployee)

    const fetchData = useCallback(async (q = '', pageNr = 0, itemsPerPage = 25) => {
        setIsUpdating(true)
        await getEmployeesAction({ search: q, page: pageNr, limit: itemsPerPage })
        setIsUpdating(false)
    }, [getEmployeesAction])

    useEffect(() => {
        fetchData(searchTerm, pageNr - 1, itemsPerPage)
        // eslint-disable-next-line
    }, [])

    const onPaginationChange = (e, newPageNr) => {
        setPageNr(newPageNr)
        fetchData(searchTerm, newPageNr - 1, itemsPerPage)
    }

    const deleteEmployee = async () => {
        console.log("deleteEmployee", targetEmployee)
        if (!((targetEmployee && targetEmployee._id))) {
            throw new Error("targetEmployee is invalid!")
        }

        setIsDeleting(true)
        await deleteEmployeeAction(targetEmployee._id)
        setShowDeleteEmployeeDialog(false)
        setTargetEmployee(undefined)
        setIsDeleting(false)
        fetchData(searchTerm, pageNr - 1, itemsPerPage)
    }

    const searchFieldOnChange = (e) => {
        setSearchTerm(e.target.value)
        fetchData(e.target.value, pageNr - 1, itemsPerPage)
    }

    return (
        <>
            <Breadcrumbler levels={[
                // { title: localStorage.getItem('currentTenantName'), url: '/tenant' },
                { title: t("Employees"), url: '/employees' }]} />

            <Typography variant="h1" component="div" sx={{ mb: '2rem' }}>
                {t("Employees")}
            </Typography>

            <Grid container spacing={2} sx={{ mb: '1rem' }}>
                <Grid item xs={8} sm={8} lg={6}>
                    <TextField
                        label="Suche"
                        id="outlined-size-small"
                        size="small"
                        fullWidth={true}
                        placeholder="Suche nach Vorname, Nachname, Email oder Rolle ..."
                        focused
                        onChange={searchFieldOnChange}
                    ></TextField>
                </Grid>
                <Grid item xs={4} sm={4} lg={6} textAlign='right'>
                    <Fab color="primary" aria-label="add" size="small" onClick={() => { setShowEmployeeForm(true) }}>
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>

            {isUpdating &&
                <Container align="center">
                    <CircularProgress />
                </Container>
            }

            {!isUpdating &&
                <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>{t('Lastname')}</TableCell>
                                    <TableCell>{t('Firstname')}</TableCell>
                                    <TableCell>{t('Email')}</TableCell>
                                    <TableCell>{t('Role')}</TableCell>
                                    <TableCell align="right">{t('Created at')}</TableCell>
                                    <TableCell align="right">&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {employees && employees.data && Array.isArray(employees.data) && employees.data.sort((a, b) => { return a.lastName > b.lastName }).map((employee, index) => (
                                    <TableRow
                                        key={employee._id}
                                        sx={{
                                            verticalAlign: 'top',
                                            '&:last-child td, &:last-child th': { border: 0 }
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {((pageNr - 1) * itemsPerPage) + index + 1}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {employee.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {employee.firstName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {employee.email}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {employee.role}
                                        </TableCell>
                                        <TableCell align="right">{moment(employee.createdAt).format(t("date.format") + " HH:mm")}</TableCell>
                                        <TableCell align="right" style={{ whiteSpace: 'pre' }}>
                                            <IconButton onClick={() => { setTargetEmployee(employee); setShowEmployeeForm(true) }} aria-label="delete">
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => { setTargetEmployee(employee); setShowDeleteEmployeeDialog(true) }} aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box display='flex' justifyContent='center' sx={{ pt: '1rem' }}>
                        <Pagination onChange={onPaginationChange} page={pageNr} count={(employees && employees.pagination && employees.pagination.total) ? (Math.floor(employees.pagination.total / itemsPerPage) + 1) : 1} siblingCount={1} boundaryCount={1} showFirstButton showLastButton />
                    </Box>
                </>
            }

            <Dialog
                open={showDeleteEmployeeDialog}
                onClose={() => { alert('Close') }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Delete Employee')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Do you really want to delete this Employee?')}<br />
                        <br />
                        {targetEmployee && `Name:  ${targetEmployee.firstName} ${targetEmployee.lastName}`}<br />
                        {targetEmployee && `Email: ${targetEmployee.email}`}<br />
                        <br />
                    </DialogContentText>
                    {isDeleting &&
                        <Container align="center">
                            <CircularProgress />
                        </Container>
                    }
                </DialogContent>
                <DialogActions sx={{ mb: '1rem', p: '6px 24px' }}>
                    <Button onClick={() => { setShowDeleteEmployeeDialog(false) }} variant="outlined" autoFocus disabled={isDeleting} >{t('Cancel')}</Button>
                    <Button onClick={() => { deleteEmployee() }} variant="contained" color="error" disabled={isDeleting}>{t('Delete Employee')}</Button>
                </DialogActions>
            </Dialog>


            {showEmployeeForm &&
                <EmployeeForm employee={targetEmployee} closeDialog={() => { setShowEmployeeForm(false); setTargetEmployee(undefined); fetchData() }} />
            }
        </>
    )
}
export default Employees
