import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { ReactComponent as MainLogo } from '../../images/rieber-rcm-logo-2022-09-05.svg'
import { useNavigate } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import { useStoreActions } from 'easy-peasy'
import { useEffect } from 'react'
import { Link } from '@mui/material'
import { useAuth } from 'react-oidc-context'

const NavBar = () => {
    const auth = useAuth()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const context = useStoreState(state => state.context)
    const getCurrentTenantId = useStoreActions(actions => actions.getCurrentTenantId)
    const [currentTenantId, setCurrentTenantId] = React.useState(undefined)

    const [anchorElNav, setAnchorElNav] = React.useState(null)
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = (event) => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const goToPath = (path) => {
        handleCloseNavMenu()
        handleCloseUserMenu()
        navigate(path)
    }

    const logout = () => {
        try {
            auth.removeUser()
            auth.signoutSilent({ id_token_hint: auth.user?.id_token })
            localStorage.clear()
            setTimeout(() => {
                window.location.reload();
            }, 500)
        } catch (error) {
            console.log(error)
        }

    }

    const getDisplayName = (context) => {
        if (context.firstName && context.lastName) {
            return context.firstName + ' ' + context.lastName
        } else if (context.email) {
            return context.email
        } else {
            return <span>&nbsp;</span>
        }
    }

    useEffect(() => {
        setCurrentTenantId(getCurrentTenantId())
    }, [setCurrentTenantId, getCurrentTenantId, currentTenantId, context])

    return (
        <AppBar position="static" style={{
            marginBottom: 40
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <IconButton sx={{ height: 50, width: 97 }} href="/tasks">
                            <MainLogo style={{ height: 50, width: 97 }} />
                        </IconButton>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                    </Typography>


                    {/* SMALL SCREEN BURGER MENU */}
                    {/* SMALL SCREEN BURGER MENU */}
                    {/* SMALL SCREEN BURGER MENU */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {context && context.roles && Array.isArray(context.roles) && context.roles.includes('Admin') &&
                                <MenuItem key={'tenants'} onClick={() => { goToPath('tenants') }}>
                                    <Typography textAlign="center">{t('Tenants')}</Typography>
                                </MenuItem>
                            }
                            {currentTenantId &&
                                <MenuItem key={'tasks'} onClick={() => { goToPath('tasks') }}>
                                    <Typography textAlign="center">{t('Tasks')}</Typography>
                                </MenuItem>
                            }
                            {currentTenantId &&
                                <MenuItem key={'carparks'} onClick={() => { goToPath('carparks') }}>
                                    <Typography textAlign="center">{t('Car parks')}</Typography>
                                </MenuItem>
                            }
                            {currentTenantId &&
                                <MenuItem key={'cars'} onClick={() => { goToPath('cars') }}>
                                    <Typography textAlign="center">{t('Cars')}</Typography>
                                </MenuItem>
                            }
                            {currentTenantId && context && context.roles && Array.isArray(context.roles) && (context.roles.includes('Admin') || context.roles.includes('ServiceManager')) &&
                                <MenuItem key={'keylockers'} onClick={() => { goToPath('keylockers') }}>
                                    <Typography textAlign="center">{t('Keylocker')}</Typography>
                                </MenuItem>
                            }
                            {currentTenantId && context && context.roles && Array.isArray(context.roles) && (context.roles.includes('Admin') || context.roles.includes('ServiceManager')) &&
                                <MenuItem key={'employees'} onClick={() => { goToPath('employees') }}>
                                    <Typography textAlign="center">{t('Employees')}</Typography>
                                </MenuItem>
                            }
                        </Menu>
                    </Box>


                    {/* BIG SCREEN NORMAL MENU */}
                    {/* BIG SCREEN NORMAL MENU */}
                    {/* BIG SCREEN NORMAL MENU */}
                    <Box sx={{ width: '100%', justifyContent: 'center', display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <IconButton sx={{ height: 50, width: 97 }} href="/tasks">
                            <MainLogo style={{ height: 50, width: 97 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {context && context.roles && Array.isArray(context.roles) && context.roles.includes('Admin') &&
                            <Button key={'tenants'} href="/tenants" sx={{ my: 2, color: 'white', display: 'block' }}>{t('Tenants')}</Button>
                        }
                        {currentTenantId &&
                            <Button key={'tasks'} href="/tasks" sx={{ my: 2, color: 'white', display: 'block' }}>{t('Tasks')}</Button>
                        }
                        {currentTenantId &&
                            <Button key={'carparks'} href="/carparks" sx={{ my: 2, color: 'white', display: 'block' }}>{t('Car parks')}</Button>
                        }
                        {currentTenantId &&
                            <Button key={'cars'} href="/cars" sx={{ my: 2, color: 'white', display: 'block' }}>{t('Cars')}</Button>
                        }
                        {currentTenantId && context && context.roles && Array.isArray(context.roles) && (context.roles.includes('Admin') || context.roles.includes('ServiceManager')) &&
                            <Button key={'keylockers'} href="/keylockers" sx={{ my: 2, color: 'white', display: 'block' }}>{t('Keylocker')}</Button>
                        }
                        {currentTenantId && context && context.roles && Array.isArray(context.roles) && (context.roles.includes('Admin') || context.roles.includes('ServiceManager')) &&
                            <Button key={'employees'} href="/employees" sx={{ my: 2, color: 'white', display: 'block' }}>{t('Employees')}</Button>
                        }
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Box sx={{ textAlign: 'center' }}>
                            <IconButton onClick={handleOpenUserMenu} size="medium" sx={{ p: 0 }}>
                                <Avatar sx={{ width: 24, height: 24 }} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                            {context &&
                                <div style={{ fontSize: 10, marginTop: 0 }} title={context && context.email}>{getDisplayName(context)}</div>
                            }
                        </Box>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key={"profile"}>
                                <Link style={{ textDecoration: 'none' }} target='_blank' href={`${window.__RUNTIME_CONFIG__.KC_URL}/account`}>
                                    <Typography textAlign="center">Profil verwalten</Typography>
                                </Link>
                            </MenuItem>
                            <MenuItem key={"Logout"} onClick={() => { logout() }}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default NavBar
